import { useCallback } from 'react';
import { RHFUploadAvatar } from 'src/components/hook-form';
import { useFormContext } from 'react-hook-form';

const UserAvatar = ({ user, updateAvatar }) => {
  const methods = useFormContext();
  const { setValue } = methods;

  const userAlreadyExist = user;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const previewFileUrl = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('avatar', previewFileUrl);
        setValue('avatarFile', file);
      }

      if (userAlreadyExist) {
        updateAvatar({
          variables: {
            userId: user.id,
            avatar: file,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  return <RHFUploadAvatar name="avatar" onDrop={handleDrop} required sx={{ width: 84, height: 84 }} />;
};

export default UserAvatar;
