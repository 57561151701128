import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { isMobile } from 'react-device-detect';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <Helmet>
        <title>{`${title} | Alfred Eyes`}</title>
        {meta}
      </Helmet>

      <Box
        ref={ref}
        sx={{
          ...(isAuthenticated && { mt: isMobile ? 2 : 5 }),
        }}
        {...other}
      >
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
