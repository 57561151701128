import { gql } from '@apollo/client';

const GET_CURRENT_DEVICE = gql`
  query getCurrentDevice($name: String!, $type: String!, $identifier: String!) {
    getCurrentDevice(name: $name, type: $type, identifier: $identifier) {
      id
      identifier
      name
      type
      authorizedAt
      isMuted
      inactive
    }
  }
`;

export { GET_CURRENT_DEVICE };
