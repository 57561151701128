import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import StoreIcon from '@mui/icons-material/Store';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { PATH_DASHBOARD } from '../../../routes/paths';
import USER_ROLES from '../../../constants/userRoles';

const useNavConfig = () => {
  const navConfig = [
    {
      items: [
        {
          title: 'Magasins',
          value: 'stores',
          path: PATH_DASHBOARD.stores.list,
          Icon: StoreIcon,
          IconOutlined: StoreOutlinedIcon,
          roles: [USER_ROLES.ADMIN],
        },
        {
          title: 'Contacts',
          value: 'contacts',
          path: PATH_DASHBOARD.contacts,
          Icon: PeopleIcon,
          IconOutlined: PeopleOutlinedIcon,
          roles: [USER_ROLES.ADMIN],
        },
        {
          title: 'Accueil',
          value: 'home',
          Icon: HomeIcon,
          IconOutlined: HomeOutlinedIcon,
          path: PATH_DASHBOARD.home,
          roles: [USER_ROLES.EMPLOYEE, USER_ROLES.STORE],
        },
        {
          title: 'Alertes',
          value: 'alerts',
          Icon: VideocamIcon,
          IconOutlined: VideocamOutlinedIcon,
          path: PATH_DASHBOARD.alerts.list,
          roles: [USER_ROLES.EMPLOYEE, USER_ROLES.STORE, USER_ROLES.ADMIN],
        },
        {
          title: 'Profil',
          value: 'profile',
          Icon: AccountCircleIcon,
          IconOutlined: AccountCircleOutlinedIcon,
          path: PATH_DASHBOARD.profile,
          roles: [USER_ROLES.EMPLOYEE, USER_ROLES.STORE],
        },
      ],
    },
  ];

  return navConfig;
};

export default useNavConfig;
