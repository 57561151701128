import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import AccountPopover from '../header/AccountPopover';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  zIndex: 999,
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

export default function NavbarAccount() {
  const { user } = useAuth();

  return (
    <RootStyle>
      <AccountPopover />
      <Box
        sx={{
          ml: 2,
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.shorter,
            }),
        }}
      >
        <Typography variant="subtitle2" noWrap>
          {user?.displayName}
        </Typography>
      </Box>
    </RootStyle>
  );
}
