import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2, 2),
}));

EmptyContent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default function EmptyContent({ title, description, children, ...other }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setVisible(true), 500);
  }, []);

  return visible ? (
    <RootStyle {...other}>
      <Stack direction="row" justifyContent="center" sx={{ height: 'auto', width: '100%' }}>
        <Stack>
          <Typography variant="h3">{title}</Typography>
          {description && <Typography variant="body2">{description}</Typography>}
          {children}
        </Stack>
      </Stack>
    </RootStyle>
  ) : null;
}
