import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import Inactive from 'src/pages/Inactive';
import LoadingScreen from 'src/components/LoadingScreen';
import useQueryParams from 'src/hooks/useQueryParams';
import SignUp from 'src/pages/auth/SignUp';
import Login from 'src/pages/auth/Login';

const AuthGuard = ({ children }) => {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const { pathname } = useLocation();
  const { invitationCode, source } = useQueryParams();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const canSignUp = invitationCode && source;

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    if (canSignUp) return <SignUp invitationCode={invitationCode} />;
    return <Login />;
  }

  if (user.inactive) return <Inactive />;

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
