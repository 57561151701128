import { Outlet, useLocation } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import useAlertsFilters from 'src/hooks/useAlertsFilters';
import useResponsive from 'src/hooks/useResponsive';
// hooks;
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import useNavConfig from './navbar/NavConfig';

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick' && prop !== 'isEmbedded' && prop !== 'statusBarHeight',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingBottom: HEADER.MOBILE_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

const DashboardLayout = () => {
  const { collapseClick } = useCollapseDrawer();
  const { filtersState } = useAlertsFilters();
  const isDesktop = useResponsive('up', 'lg');
  const [navConfig] = useNavConfig();

  const { pathname } = useLocation();

  const allowedMenuPaths = navConfig.items.map((item) => item.path);
  const isCurrentPathAllowedMenuPaths = allowedMenuPaths.includes(pathname);

  const displayHeaderExtraConditions = [!filtersState.advancedFilters.isDrawerOpen];
  const hasAtLeastOneExtraCondition = displayHeaderExtraConditions.some((condition) => condition);

  const shouldDisplayHeader = isCurrentPathAllowedMenuPaths && hasAtLeastOneExtraCondition && !isDesktop;

  return (
    <>
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {isDesktop && <NavbarVertical />}
        {shouldDisplayHeader && <DashboardHeader />}

        <MainStyle collapseClick={collapseClick}>
          <Outlet />
        </MainStyle>
      </Box>
    </>
  );
};

export default DashboardLayout;
