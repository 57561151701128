import { styled } from '@mui/material/styles';
import { useLottie } from 'lottie-react';
import { Button, Typography, Container, Box } from '@mui/material';
import Page from '../components/Page';
import inactiveAnimation from '../assets/animations/inactive.json';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function Inactive() {
  const options = {
    animationData: inactiveAnimation,
    loop: true,
  };

  const { View } = useLottie(options);
  return (
    <Page title="Compte Inactif">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Votre compte est temporairement inactif
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>Veuillez contacter un administrateur.</Typography>

          <Box sx={{ height: 'auto', width: '70%' }}>{View}</Box>

          <Typography sx={{ marginBottom: 2 }}>Votre compte a été activé ? Veuillez rafraichir</Typography>
          <Button variant="contained" size="large" onClick={() => window.location.reload()}>
            Rafraichir
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
