import { useMutation } from '@apollo/client';

import useQueryErrors from './useQueryErrors';

const useSafeMutation = (query, queryOptions, errorsOptions) => {
  const [mutation, { error, ...queryResult }] = useMutation(query, queryOptions);
  useQueryErrors(error, { silentError: true, ...errorsOptions });

  return [mutation, { error, ...queryResult }];
};

export default useSafeMutation;
