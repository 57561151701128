import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PathHistoryContext = createContext();

const PathHistoryProvider = ({ children, maxEntries = 10 }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = useState(location.pathname + location.search);
  const [prevPath, setPrevPath] = useState();
  const [pathHistory, setPathHistory] = useState([location.pathname + location.search]);

  useEffect(() => {
    const fullPath = location.pathname + location.search;
    if (fullPath !== currentPath) {
      setPrevPath(currentPath);
      setCurrentPath(fullPath);
      setPathHistory((prev) => {
        const newHistory = [fullPath, ...prev];
        return newHistory.slice(0, maxEntries);
      });
    }
  }, [location, currentPath, maxEntries]);

  const goBack = useCallback(
    (steps = 1, queryParams = {}) => {
      if (pathHistory.length > steps) {
        let targetPath = pathHistory[steps];

        // Append new query params if provided
        if (Object.keys(queryParams).length > 0) {
          const url = new URL(targetPath, window.location.origin);
          Object.entries(queryParams).forEach(([key, value]) => {
            url.searchParams.set(key, value);
          });
          targetPath = url.pathname + url.search;
        }

        navigate(targetPath, { replace: true });
      } else {
        navigate(-steps);
      }
    },
    [navigate, pathHistory]
  );

  const getPreviousPath = useCallback((index = 1) => pathHistory[index] || null, [pathHistory]);

  return (
    <PathHistoryContext.Provider
      value={{
        prevPath,
        currentPath,
        pathHistory,
        goBack,
        getPreviousPath,
      }}
    >
      {children}
    </PathHistoryContext.Provider>
  );
};

export { PathHistoryContext, PathHistoryProvider };
