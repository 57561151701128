import { useContext } from 'react';
import { WebviewContext } from '../contexts/WebviewContext';

const useWebview = () => {
  const context = useContext(WebviewContext);

  if (!context) throw new Error('Webview context must be use inside WebviewProvider');

  return context;
};

export default useWebview;
