import PropTypes from 'prop-types';
import React, { createContext, useCallback, useState } from 'react';
import { AlertDialog, ConfirmDialog, PromptDialog } from '../components/dialogs';

const DIALOGS = {
  ALERT: AlertDialog,
  CONFIRM: ConfirmDialog,
  PROMPT: PromptDialog,
};

let dialogConfirmResolve = () => null;
let dialogDiscardResolve = () => null;

const DialogsContext = createContext();

const DialogsProvider = ({ children }) => {
  const [dialogType, setDialogType] = useState();
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogMessage, setDialogMessage] = useState();

  const triggerDialog = ({ message, title, type }) => {
    setDialogType(type);
    setDialogTitle(title);
    setDialogMessage(message);
    setOpen(true);
  };

  const alert = useCallback(({ message, title }) => {
    triggerDialog({ message, title: title || 'Attention', type: 'ALERT' });
    return new Promise((resolve) => {
      dialogConfirmResolve = () => {
        setOpen(false);
        resolve(true);
      };
    });
  }, []);

  const confirm = useCallback(
    ({ message, title }) =>
      new Promise((resolve) => {
        triggerDialog({ message, title: title || 'Confirmation', type: 'CONFIRM' });
        dialogConfirmResolve = () => {
          setOpen(false);
          resolve(true);
        };
        dialogDiscardResolve = () => {
          setOpen(false);
          resolve(false);
        };
      }),
    []
  );

  const prompt = useCallback(({ message, title }) => {
    triggerDialog({ message, title: title || 'Saisissez', type: 'PROMPT' });
    return new Promise((resolve) => {
      dialogConfirmResolve = (promptedValue) => {
        setOpen(false);
        resolve(promptedValue);
      };
      dialogDiscardResolve = () => {
        setOpen(false);
        resolve(null);
      };
    });
  }, []);

  const DialogComponent = DIALOGS[dialogType];

  return (
    <DialogsContext.Provider value={{ alert, confirm, prompt }}>
      {children}
      {DialogComponent && (
        <DialogComponent
          message={dialogMessage}
          onConfirm={dialogConfirmResolve}
          onDiscard={dialogDiscardResolve}
          open={open}
          title={dialogTitle}
        />
      )}
    </DialogsContext.Provider>
  );
};

DialogsProvider.propTypes = {
  children: PropTypes.node,
};

export { DialogsContext, DialogsProvider };
