import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { createContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isNullOrUndefined } from 'util';
import getSafeArray from '../utils/getSafeArray';

const QueryParamsContext = createContext();

const QueryParamsProvider = ({ children }) => {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const queryParamsObject = Object.fromEntries(query.entries());

  const changeParameterValue = useCallback(
    (parameter, value, ...args) => {
      if (value !== null) {
        queryParamsObject[parameter] = value;
      } else {
        delete queryParamsObject?.[parameter];
      }

      navigate(`?${new URLSearchParams(queryParamsObject).toString()}`, ...args);
    },
    [queryParamsObject, navigate]
  );

  const changeParameters = useCallback(
    (parameters, ...args) => {
      Object.keys(parameters).forEach((param) => {
        if (Boolean(queryParamsObject[param]) && isNullOrUndefined(parameters[param])) {
          delete queryParamsObject?.[param];
          delete parameters?.[param];
        }
      });
      navigate(`?${new URLSearchParams(merge(queryParamsObject, parameters)).toString()}`, ...args);
    },
    [navigate, queryParamsObject]
  );

  const removeQueryParameters = useCallback(
    (parametersToRemove, ...args) => {
      const paramsArray = getSafeArray(parametersToRemove);
      paramsArray.forEach((parameter) => {
        delete queryParamsObject[parameter];
      });
      navigate(`?${new URLSearchParams(queryParamsObject).toString()}`, ...args);
    },
    [navigate, queryParamsObject]
  );

  return (
    <QueryParamsContext.Provider
      value={{ changeParameterValue, changeParameters, removeQueryParameters, ...queryParamsObject }}
    >
      {children}
    </QueryParamsContext.Provider>
  );
};

QueryParamsProvider.propTypes = {
  children: PropTypes.node,
};

export { QueryParamsContext, QueryParamsProvider };
