import { Typography, Link } from '@mui/material';
import { RHFTextField } from '../../../components/hook-form';

export default function EmailForm({ isDesktop }) {
  return (
    <>
      <RHFTextField name="email" label="Email" placeholder="Email" type="text" required />
      {!isDesktop && (
        <Typography variant="caption" sx={{ color: 'text.tertiary' }}>
          En continuant, vous confirmez avoir lu et vous acceptez les&nbsp;
          <Link href="https://alfred-eyes.ai/mentions-legales/" target="_blank" rel="noopener">
            conditions générales d’utilisation.
          </Link>
        </Typography>
      )}
    </>
  );
}
