const expectedErrors = {
  EXPIRED_LINK: {
    code: 'EXPIRED_LINK',
    message: 'Le lien a expiré',
  },
  UNAUTHENTICATED: {
    code: 'UNAUTHENTICATED',
    message: 'Vous avez été déconnecté',
  },
  ALERT_UNAVAILABLE: {
    code: 'ALERT_UNAVAILABLE',
    message: "Cette alerte n'est plus disponible",
  },
};

export default expectedErrors;
