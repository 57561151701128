import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import USER_ROLES from 'src/constants/userRoles';
import deepCompareValues from 'src/utils/deepCompareValues';

const initialState = {
  falsePositive: null,
  qualified: null,
  favorite: null,
  search: '',
  isSearching: false,
  isSearchLayoutActive: false,
  hasTyped: false,
  // advancedFilters
  advancedFilters: {
    dates: {
      start: null,
      end: null,
    },
    storeId: null,
    isDrawerOpen: false,
    counter: 0,
    cameraIds: [],
    tags: {
      locationId: null,
      thiefProfileId: null,
      theftTypeId: null,
    },
    estimatedValues: {
      minimum: '',
      maximum: '',
    },
  },
};

const ALERTS_FILTERS_ACTIONS = {
  SET_FALSE_POSITIVE: 'SET_FALSE_POSITIVE',
  SET_QUALIFIED: 'SET_QUALIFIED',
  SET_FAVORITE: 'SET_FAVORITE',
  SET_SEARCH: 'SET_SEARCH',
  SET_IS_SEARCH_FOCUSED: 'SET_IS_SEARCH_FOCUSED',
  SET_IS_SEARCH_LAYOUT_ACTIVE: 'SET_IS_SEARCH_LAYOUT_ACTIVE',
  RESET_SEARCH: 'RESET_SEARCH',
  QUIT_SEARCH: 'QUIT_SEARCH',
  RESET_ALL_FILTERS: 'RESET_ALL_FILTERS',
  // advancedFilters
  SET_IS_DRAWER_OPEN: 'SET_IS_DRAWER_OPEN',
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  BLANK_ADVANCED_FILTERS: 'BLANK_ADVANCED_FILTERS',
  SET_STORE_ID: 'SET_STORE_ID',
  SET_COUNTER: 'SET_COUNTER',
  SET_CAMERA_IDS: 'SET_CAMERA_IDS',
  SET_LOCATION_TAG_ID: 'SET_LOCATION_TAG_ID',
  SET_THIEF_PROFILE_TAG_ID: 'SET_THIEF_PROFILE_TAG_ID',
  SET_THEFT_TYPE_TAG_ID: 'SET_THEFT_TYPE_TAG_ID',
  SET_ESTIMATED_VALUE_MINIMUM: 'SET_ESTIMATED_VALUE_MINIMUM',
  SET_ESTIMATED_VALUE_MAXIMUM: 'SET_ESTIMATED_VALUE_MAXIMUM',
};

const actions = {
  [ALERTS_FILTERS_ACTIONS.SET_FALSE_POSITIVE]: (state, payload) => ({
    ...state,
    falsePositive: payload,
  }),
  [ALERTS_FILTERS_ACTIONS.SET_QUALIFIED]: (state, payload) => ({
    ...state,
    qualified: payload,
  }),
  [ALERTS_FILTERS_ACTIONS.SET_FAVORITE]: (state, payload) => ({
    ...state,
    favorite: payload,
  }),
  [ALERTS_FILTERS_ACTIONS.SET_SEARCH]: (state, payload) => ({
    ...state,
    search: payload,
  }),
  [ALERTS_FILTERS_ACTIONS.SET_IS_SEARCH_FOCUSED]: (state, payload) => ({
    ...state,
    isSearching: payload,
  }),
  [ALERTS_FILTERS_ACTIONS.SET_IS_SEARCH_LAYOUT_ACTIVE]: (state, payload) => ({
    ...state,
    isSearchLayoutActive: payload,
  }),
  [ALERTS_FILTERS_ACTIONS.RESET_SEARCH]: (state) => ({
    ...state,
    search: initialState.search,
    isSearching: initialState.isSearching,
  }),
  [ALERTS_FILTERS_ACTIONS.QUIT_SEARCH]: (state) => ({
    ...state,
    search: initialState.search,
    isSearching: initialState.isSearching,
  }),
  [ALERTS_FILTERS_ACTIONS.SET_HAS_TYPED]: (state, payload) => ({
    ...state,
    hasTyped: payload,
  }),
  [ALERTS_FILTERS_ACTIONS.SET_IS_DRAWER_OPEN]: (state, payload) => ({
    ...state,
    advancedFilters: {
      ...state.advancedFilters,
      isDrawerOpen: payload,
    },
  }),
  // ADVANCED FILTERS
  [ALERTS_FILTERS_ACTIONS.SET_START_DATE]: (state, payload) => ({
    ...state,
    advancedFilters: {
      ...state.advancedFilters,
      dates: {
        ...state.advancedFilters.dates,
        start: payload,
      },
    },
  }),
  [ALERTS_FILTERS_ACTIONS.SET_END_DATE]: (state, payload) => ({
    ...state,
    advancedFilters: {
      ...state.advancedFilters,
      dates: {
        ...state.advancedFilters.dates,
        end: payload,
      },
    },
  }),
  [ALERTS_FILTERS_ACTIONS.BLANK_ADVANCED_FILTERS]: (state) => ({
    ...state,
    advancedFilters: {
      ...initialState.advancedFilters,
      isDrawerOpen: state.advancedFilters.isDrawerOpen,
      tags: {
        ...initialState.advancedFilters.tags,
      },
      estimatedValues: {
        ...initialState.advancedFilters.estimatedValues,
      },
    },
  }),
  [ALERTS_FILTERS_ACTIONS.SET_STORE_ID]: (state, payload) => ({
    ...state,
    advancedFilters: {
      ...state.advancedFilters,
      storeId: payload,
    },
  }),
  [ALERTS_FILTERS_ACTIONS.SET_COUNTER]: (state, payload) => ({
    ...state,
    advancedFilters: {
      ...state.advancedFilters,
      counter: payload,
    },
  }),
  [ALERTS_FILTERS_ACTIONS.SET_CAMERA_IDS]: (state, payload) => ({
    ...state,
    advancedFilters: {
      ...state.advancedFilters,
      cameraIds: payload,
    },
  }),
  [ALERTS_FILTERS_ACTIONS.SET_LOCATION_TAG_ID]: (state, payload) => ({
    ...state,
    advancedFilters: {
      ...state.advancedFilters,
      tags: {
        ...state.advancedFilters.tags,
        locationId: payload,
      },
    },
  }),
  [ALERTS_FILTERS_ACTIONS.SET_THIEF_PROFILE_TAG_ID]: (state, payload) => ({
    ...state,
    advancedFilters: {
      ...state.advancedFilters,
      tags: {
        ...state.advancedFilters.tags,
        thiefProfileId: payload,
      },
    },
  }),
  [ALERTS_FILTERS_ACTIONS.SET_THEFT_TYPE_TAG_ID]: (state, payload) => ({
    ...state,
    advancedFilters: {
      ...state.advancedFilters,
      tags: {
        ...state.advancedFilters.tags,
        theftTypeId: payload,
      },
    },
  }),
  [ALERTS_FILTERS_ACTIONS.SET_ESTIMATED_VALUE_MINIMUM]: (state, payload) => ({
    ...state,
    advancedFilters: {
      ...state.advancedFilters,
      estimatedValues: {
        ...state.advancedFilters.estimatedValues,
        minimum: payload,
      },
    },
  }),
  [ALERTS_FILTERS_ACTIONS.SET_ESTIMATED_VALUE_MAXIMUM]: (state, payload) => ({
    ...state,
    advancedFilters: {
      ...state.advancedFilters,
      estimatedValues: {
        ...state.advancedFilters.estimatedValues,
        maximum: payload,
      },
    },
  }),
  [ALERTS_FILTERS_ACTIONS.RESET_ALL_FILTERS]: () => ({
    ...initialState,
  }),
};

const createReducer = (reducerActions) => (state, action) =>
  reducerActions[action.type] ? reducerActions[action.type](state, action.payload) : state;

const AlertsFiltersContext = createContext();

const AlertsFiltersContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(createReducer(actions), initialState);

  const ACTIONS_HANDLERS = useMemo(
    () => ({
      setFalsePositive: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_FALSE_POSITIVE, payload });
      },
      setQualified: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_QUALIFIED, payload });
      },
      setFavorite: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_FAVORITE, payload });
      },
      setSearch: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_SEARCH, payload });
      },
      setIsSearchFocused: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_IS_SEARCH_FOCUSED, payload });
      },
      setIsSearchLayoutActive: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_IS_SEARCH_LAYOUT_ACTIVE, payload });
      },
      resetSearch: () => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.RESET_SEARCH });
      },
      quitSearch: () => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.QUIT_SEARCH });
      },
      setHasTyped: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_HAS_TYPED, payload });
      },
      // advancedFilters
      setIsDrawerOpen: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_IS_DRAWER_OPEN, payload });
      },
      setStartDate: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_START_DATE, payload });
      },
      setEndDate: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_END_DATE, payload });
      },
      blankAdvancedFilters: () => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.BLANK_ADVANCED_FILTERS });
      },
      setStoreId: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_STORE_ID, payload });
      },
      setCounter: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_COUNTER, payload });
      },
      setCameraIds: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_CAMERA_IDS, payload });
      },
      setLocationTagId: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_LOCATION_TAG_ID, payload });
      },
      setThiefProfileTagId: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_THIEF_PROFILE_TAG_ID, payload });
      },
      setTheftTypeTagId: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_THEFT_TYPE_TAG_ID, payload });
      },
      resetCameraIds: () => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_CAMERA_IDS, payload: initialState.advancedFilters.cameraIds });
      },
      resetLocationTagId: () => {
        dispatch({
          type: ALERTS_FILTERS_ACTIONS.SET_LOCATION_TAG_ID,
          payload: initialState.advancedFilters.tags.locationId,
        });
      },
      resetThiefProfileTagId: () => {
        dispatch({
          type: ALERTS_FILTERS_ACTIONS.SET_THIEF_PROFILE_TAG_ID,
          payload: initialState.advancedFilters.tags.thiefProfileId,
        });
      },
      resetTheftTypeTagId: () => {
        dispatch({
          type: ALERTS_FILTERS_ACTIONS.SET_THEFT_TYPE_TAG_ID,
          payload: initialState.advancedFilters.tags.theftTypeId,
        });
      },
      setEstimatedValueMinimum: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_ESTIMATED_VALUE_MINIMUM, payload });
      },
      setEstimatedValueMaximum: (payload) => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.SET_ESTIMATED_VALUE_MAXIMUM, payload });
      },
      resetAllFilters: () => {
        dispatch({ type: ALERTS_FILTERS_ACTIONS.RESET_ALL_FILTERS });
      },
    }),
    []
  );

  useEffect(() => {
    const advancedFilters = { ...state.advancedFilters };
    delete advancedFilters.isDrawerOpen;
    delete advancedFilters.counter;
    const valuesChangedCount = deepCompareValues(advancedFilters, initialState.advancedFilters);

    const hasCounterChanged = state.advancedFilters.counter !== valuesChangedCount;
    if (hasCounterChanged) {
      ACTIONS_HANDLERS.setCounter(valuesChangedCount);
    }
  }, [ACTIONS_HANDLERS, state]);

  useEffect(() => {
    const isSearchLayoutActive = state.search.length > 0 || state.isSearching;
    ACTIONS_HANDLERS.setIsSearchLayoutActive(isSearchLayoutActive);
  }, [state.search, state.isSearching, ACTIONS_HANDLERS]);

  useEffect(() => {
    ACTIONS_HANDLERS.setHasTyped(state.search.length > 0);
  }, [state.search, ACTIONS_HANDLERS]);

  useEffect(() => {
    ACTIONS_HANDLERS.resetCameraIds();
    ACTIONS_HANDLERS.resetLocationTagId();
    ACTIONS_HANDLERS.resetThiefProfileTagId();
    ACTIONS_HANDLERS.resetTheftTypeTagId();
  }, [ACTIONS_HANDLERS, state.advancedFilters.storeId]);

  const ALERTS_FILTERS = [
    {
      icon: '/assets/alerts/filters.svg',
      onClick: () => {
        ACTIONS_HANDLERS.setIsDrawerOpen(true);
      },
      tooltipMessage: 'Filtres avancés',
      roles: Object.values(USER_ROLES),
    },
    {
      label: 'À vérifier',
      onClick: () => {
        const payload = state.qualified === false ? initialState.qualified : false;
        ACTIONS_HANDLERS.setQualified(payload);
        ACTIONS_HANDLERS.setFalsePositive(initialState.falsePositive);
        ACTIONS_HANDLERS.setFavorite(initialState.favorite);
      },
      isActive: state.qualified === false,
      snackbarMessage: 'Alertes à vérifier',
      tooltipMessage: 'Filtre sur les alerters qui nécessitent une vérification',
      roles: Object.values(USER_ROLES),
    },
    {
      label: 'Vol confirmé',
      onClick: () => {
        const payload = state.falsePositive === false ? initialState.falsePositive : false;
        ACTIONS_HANDLERS.setFalsePositive(payload);
        ACTIONS_HANDLERS.setQualified(initialState.qualified);
        ACTIONS_HANDLERS.setFavorite(initialState.favorite);
      },
      isActive: state.falsePositive === false,
      snackbarMessage: 'Alertes de vol confirmé',
      tooltipMessage: 'Filtre sur les alertes qui ont été qualifiées comme des vols',
      roles: Object.values(USER_ROLES),
    },
    {
      label: 'Fausse alerte',
      onClick: () => {
        const payload = state.falsePositive === true ? initialState.falsePositive : true;
        ACTIONS_HANDLERS.setFalsePositive(payload);
        ACTIONS_HANDLERS.setQualified(initialState.qualified);
        ACTIONS_HANDLERS.setFavorite(initialState.favorite);
      },
      isActive: state.falsePositive === true,
      snackbarMessage: 'Alertes de fausse alerte',
      tooltipMessage: 'Filtre sur les alertes qui ont été qualifiées comme des fausses alertes',
      roles: Object.values(USER_ROLES),
    },
    {
      label: 'Mes favoris',
      onClick: () => {
        const payload = state.favorite === initialState.favorite ? true : initialState.favorite;
        ACTIONS_HANDLERS.setFavorite(payload);
        ACTIONS_HANDLERS.setFalsePositive(initialState.falsePositive);
        ACTIONS_HANDLERS.setQualified(initialState.qualified);
      },
      isActive: state.favorite === true,
      snackbarMessage: 'Alertes de mes favoris',
      tooltipMessage: 'Filtre sur les alertes que vous avez marquées comme favoris',
      roles: [USER_ROLES.STORE, USER_ROLES.EMPLOYEE],
      desktopOnly: true,
    },
  ];

  return (
    <AlertsFiltersContext.Provider
      value={{
        filtersState: state,
        filtersDispatch: dispatch,
        ALERTS_FILTERS,
        ACTIONS_HANDLERS,
      }}
    >
      {children}
    </AlertsFiltersContext.Provider>
  );
};
export { AlertsFiltersContext, AlertsFiltersContextProvider };
