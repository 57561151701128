import { Typography, IconButton, Dialog, Toolbar, Button, Stack } from '@mui/material';
import QuestionsAnswersItem from './QuestionsAnswersItem';
import Iconify from '../../../components/Iconify';
import stopPropagation from '../../../utils/stopPropagation';

const ACCORDION_ITEMS = [
  {
    question: 'Puis-je changer mon nom de compte ?',
    response:
      'Pour des raisons de sécurité, une fois votre compte créé, il n’est plus possible de modifier votre prénom ou nom',
  },

  {
    question: 'Comment inviter des collaborateurs ?',
    response:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  {
    question: 'Comment retirer des collaborateurs ?',
    response:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
];

const contactUs = () => {
  window.location.href = 'mailto:tech@alfred-eyes.ai';
};

const QuestionsAnswersDialog = ({ onClose, isDesktop }) => (
  <Dialog
    onClose={stopPropagation(onClose)}
    fullScreen={!isDesktop}
    fullWidth={isDesktop}
    open
    sx={{
      '.MuiDialog-paper': {
        padding: 0,
        margin: 0,
        p: (theme) => theme.spacing(6, 4),
        backgroundColor: 'white',
      },
    }}
  >
    <Stack spacing={4}>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          onClick={stopPropagation(onClose)}
          sx={{
            position: 'absolute',
            left: 0,
          }}
        >
          <Iconify
            icon="eva:arrow-back-outline"
            sx={{
              height: 26,
              width: 26,
            }}
          />
        </IconButton>
        <Typography variant="h6">Aide & Support</Typography>
      </Toolbar>

      <Stack spacing={1} flex={1}>
        {ACCORDION_ITEMS.map((accordion) => (
          <QuestionsAnswersItem key={accordion.question} accordion={accordion} />
        ))}
      </Stack>
      <Button size="large" type="submit" variant="contained" fullWidth onClick={contactUs}>
        Contactez-nous
      </Button>
    </Stack>
  </Dialog>
);

export default QuestionsAnswersDialog;
