import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import commonExpectedErrors from '../../../graphql/common/expectedErrors';

const bindError = (error, callback) => ({ ...error, on: callback });

const useQueryErrors = (error, { expectExpiredLink = false, expectedErrors, silentError = false } = {}) => {
  const { enqueueSnackbar } = useSnackbar();
  /**
   * The clean way to handle authentication errors would be to return a custom http
   * error code on server side (ex : 498)
   * This should be possible through plugins https://github.com/apollographql/apollo-server/issues/1709#issuecomment-495793375
   * Unfortunately it not because of https://github.com/apollographql/apollo-server/issues/3223
   * So in the meantime we use isAuthenticationError to avoid spamming errors
   * on simple authentication problem
   */
  useEffect(() => {
    if (error) {
      const { graphQLErrors, networkError } = error;
      const defaultErrorMessage = "Quelque chose s'est mal passé";

      let errorHandled;
      if (networkError) {
        let networkErrorHandled;
        networkError.result?.errors?.forEach(({ extensions, message }) => {
          errorHandled = true;
          // Catch expired token exception
          if (extensions?.code === commonExpectedErrors.UNAUTHENTICATED.code) {
            networkErrorHandled = true;
            if (expectExpiredLink) {
              enqueueSnackbar(commonExpectedErrors.EXPIRED_LINK.message, { variant: 'error' });
            } else {
              enqueueSnackbar(commonExpectedErrors.UNAUTHENTICATED.message, { variant: 'error' });
              // trigger login popup
              // expireUser();
            }
          } else if (!silentError) {
            enqueueSnackbar(message, { variant: 'error' });
          }
        });
        // send unexpected server errors (ie: code > 200 and not an authentication error) to datadog
        /** should opt out for 498 custom code */
        if (networkError.statusCode > 299 && !networkErrorHandled) {
          // logger.error(`[request-error] status code ${networkError.statusCode}`, { networkError });
        }
        /** the networkError.result?.errors?.forEach loop should be done here */
        // handle the critical "Request header too large" error even if silentError is true
        if (networkError.result?.error?.code === 494) {
          errorHandled = true;
          //
        }
        if (!errorHandled) {
          errorHandled = true;
          if (!silentError) {
            // extract errorMessage in custom NGINX responses if applicable
            enqueueSnackbar(networkError.result?.error?.message || networkError.message, {
              variant: 'error',
            });
          }
        }
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
          errorHandled = true;
          if (!silentError) {
            const expectedError = expectedErrors?.find((e) => e.code === extensions?.code);
            enqueueSnackbar(expectedError?.message || defaultErrorMessage, { variant: 'error' });
          }
        });
      }
      // if the error was not handled display a default message
      // this should never happen
      if (!errorHandled) {
        // logger.error(`[request-error] request error unhandled`);
        if (!silentError) enqueueSnackbar(defaultErrorMessage, { variant: 'error' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, silentError, expectedErrors]);
};

export { bindError };
export default useQueryErrors;
