import getSafeArray from '../utils/getSafeArray';

const USER_ROLES = {
  ADMIN: 'ADMIN',
  STORE: 'STORE',
  EMPLOYEE: 'EMPLOYEE',
};

const checkRole = ({ currentRole, rolesToCheck }) => {
  const safeRequiredRoles = getSafeArray(rolesToCheck);
  return safeRequiredRoles.includes(currentRole);
};

export { checkRole };
export default USER_ROLES;
