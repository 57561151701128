import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import Iconify from '../Iconify';
import StyledDialog from './StyledDialog';

const AlertDialog = ({ message, onConfirm, open, title }) => (
  <StyledDialog fullWidth maxWidth="xs" open={open}>
    <DialogTitle sx={{ p: 0 }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography>{title}</Typography>
        <IconButton onClick={onConfirm}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Toolbar>
    </DialogTitle>
    <Divider />
    <DialogContent>{message}</DialogContent>
    <Divider />
    <DialogActions sx={{ p: 2 }}>
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <Button onClick={onConfirm} variant="contained">
          Ok
        </Button>
      </Stack>
    </DialogActions>
  </StyledDialog>
);

export default AlertDialog;
