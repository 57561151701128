import { useEffect, useState } from 'react';
import { Typography, AccordionSummary, Accordion, IconButton, Avatar, Switch, styled } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import useDevice from '../../hooks/useDevice';
import WEB_APP_ACTIONS from '../../utils/webAppActions';
import sendActionToApp from '../../utils/sendActionToApp';
import Iconify from '../../components/Iconify';

const CustomAvatar = styled(Avatar)(({ embedInMenu }) => ({
  width: embedInMenu ? 20 : 24,
  height: embedInMenu ? 20 : 24,
}));

const CustomTypography = styled(Typography)(({ embedInMenu }) => ({
  fontSize: embedInMenu ? 14 : 16,
}));

const AccordionItem = ({ accordion, embedInMenu = false, handleCloseMenu }) => {
  const [isAccordionComponentOpen, setIsAccordionComponentOpen] = useState(false);
  const { label, imageUrl, Component, action } = accordion;
  const [isMuted, setIsMuted] = useState(false);
  const { device } = useDevice();
  const isDesktop = useResponsive('up', 'lg');
  const shouldRenderComponent = Component;
  const shouldDispatchAction = action;

  const handleAccordionDialogItemClick = () => {
    if (shouldDispatchAction) {
      action();
    }
    if (shouldRenderComponent) {
      setIsAccordionComponentOpen(true);
    }
  };

  const handleAccordionDialogItemClose = () => {
    setIsAccordionComponentOpen(false);
    if (handleCloseMenu) handleCloseMenu();
  };

  useEffect(() => {
    if (device) {
      setIsMuted(device.isMuted);
    }
  }, [device]);

  return (
    <Accordion
      onClick={handleAccordionDialogItemClick}
      expanded={false}
      sx={{
        background: 'none',
        borderBottom: embedInMenu ? 0 : 1,
        borderColor: '#ECECEC',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          !embedInMenu &&
          (Component ? (
            <Iconify
              icon="mdi:keyboard-arrow-right"
              sx={{
                ml: 0.5,
                width: 24,
                height: 24,
              }}
            />
          ) : (
            <Switch
              size="small"
              checked={!isMuted}
              onChange={() => {
                setIsAccordionComponentOpen(false);
                sendActionToApp({ action: WEB_APP_ACTIONS.MUTE_NOTIFICATIONS });
              }}
            />
          ))
        }
        sx={{
          padding: 0,
          py: 1,
          '& .MuiAccordionSummary-content': {
            alignItems: 'center',
            margin: 0,
            '&:hover': {
              color: 'primary.main',
            },
          },
        }}
      >
        <IconButton>
          <CustomAvatar alt={label} src={imageUrl} embedInMenu={embedInMenu} />
        </IconButton>
        <CustomTypography embedInMenu={embedInMenu}>{accordion.label}</CustomTypography>
      </AccordionSummary>
      {isAccordionComponentOpen && <Component onClose={handleAccordionDialogItemClose} isDesktop={isDesktop} />}
    </Accordion>
  );
};

export default AccordionItem;
