const anonymizeEmail = (email) => {
  const [localPart, domainPart] = email.split('@');
  if (localPart.length <= 4) return `${localPart}@${domainPart}`;
  const firstFour = localPart.substring(0, 4);
  const stars = '*'.repeat(localPart.length - 4);

  return `${firstFour}${stars}@${domainPart}`;
};

export default anonymizeEmail;
