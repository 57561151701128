import { useFormContext } from 'react-hook-form';
import { Stack, Button } from '@mui/material';
import { RHFTextField } from '../../../../components/hook-form';

export default function SecurityCodeForm({ additionalOperation }) {
  const { watch } = useFormContext();
  const formValues = watch();
  return (
    <Stack alignItems="flex-start">
      <RHFTextField
        inputProps={{ maxLength: 4 }}
        type="tel"
        name="securityCode"
        label="Code à 4 chiffres"
        placeholder="Code à 4 chiffres"
        required
      />
      <Button
        variant="text"
        onClick={() => {
          additionalOperation.operation({
            variables: additionalOperation.mapVariables(formValues),
          });
        }}
        sx={{
          color: 'primary.main',
          fontWeight: 'normal',
          fontSize: '0.75rem',
          textTransform: 'none',
          minWidth: 'fit-content',
          px: 0,
        }}
      >
        Renvoyer le code
      </Button>
    </Stack>
  );
}
