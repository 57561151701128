import React, { createContext, useState, useEffect } from 'react';

const DeviceContext = createContext();

const DeviceProvider = ({ children }) => {
  const [device, setDevice] = useState();

  useEffect(() => {
    const handleDeviceMessage = (event) => {
      try {
        const data = event.detail;
        if (data && data.type === 'DEVICE_INFO') {
          setDevice(data.device);
        }
      } catch (error) {
        console.error('Failed to parse device info:', error);
      }
    };

    window.addEventListener('messageFromNative', handleDeviceMessage);
    return () => {
      window.removeEventListener('messageFromNative', handleDeviceMessage);
    };
  }, []);

  return <DeviceContext.Provider value={{ device }}>{children}</DeviceContext.Provider>;
};

export { DeviceProvider, DeviceContext };
