import { useState } from 'react';
import { Stack, Typography, IconButton } from '@mui/material';
import MyAvatar from 'src/components/MyAvatar';
import USER_ROLES from 'src/constants/userRoles';
import Iconify from 'src/components/Iconify';
import CollaboratorDrawerActions from 'src/sections/profile/collaborators/CollaboratorDrawerActions';
import useAuth from 'src/hooks/useAuth';
import Label from 'src/components/Label';
import _ from 'lodash';

const ROLE_MAPPING = {
  [USER_ROLES.ADMIN]: 'Administrateur',
  [USER_ROLES.STORE]: 'Responsable',
  [USER_ROLES.EMPLOYEE]: 'Collaborateur',
};

const CollaboratorItem = ({ collaborator, key }) => {
  const { user } = useAuth();
  const isCurrentUser = user.id === collaborator.id;
  const [isCollaboratorDrawerOpen, setIsCollaboratorDrawerOpen] = useState(false);

  const handleCollaboratorDrawerOpen = () => {
    setIsCollaboratorDrawerOpen(true);
  };

  const handleCollaboratorDrawerClose = () => {
    setIsCollaboratorDrawerOpen(false);
  };

  return (
    <Stack spacing={2} direction="row" py={1} alignItems="center" key={key}>
      <MyAvatar
        sx={{
          width: 50,
          height: 50,
        }}
        alt={`${collaborator.firstName} ${collaborator.lastName}`}
        user={collaborator}
      />
      <Stack
        sx={{
          flex: 1,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            variant="h6"
            sx={{
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              whiteSpace: 'normal',
            }}
          >
            {`${_.capitalize(collaborator.firstName)} ${_.capitalize(collaborator.lastName)}`}
          </Typography>
          {collaborator.inactive && <Label color="error">Inactif</Label>}
        </Stack>
        <Typography
          variant="body2"
          sx={{
            color: '#8F8F8F',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          {ROLE_MAPPING[collaborator.role]} • {collaborator.email}
        </Typography>
      </Stack>
      {!isCurrentUser && (
        <IconButton onClick={handleCollaboratorDrawerOpen}>
          <Iconify
            icon="mdi:dots-horizontal"
            sx={{
              cursor: 'pointer',
              height: 24,
              width: 24,
            }}
          />
        </IconButton>
      )}
      {isCurrentUser && <Label color="primary">Vous</Label>}
      {isCollaboratorDrawerOpen && (
        <CollaboratorDrawerActions collaborator={collaborator} onClose={handleCollaboratorDrawerClose} />
      )}
    </Stack>
  );
};

export default CollaboratorItem;
