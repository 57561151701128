import React, { createContext, useEffect } from 'react';
import useQueryParams from '../hooks/useQueryParams';
import useLocalStorage from '../hooks/useLocalStorage';

const WebviewContext = createContext();

const WebviewProvider = ({ children }) => {
  const { embedded, statusBarHeight } = useQueryParams();
  const [isEmbedded, setIsEmbedded] = useLocalStorage('app-embedded-mode', false);
  const [height, setHeight] = useLocalStorage('app-status-bar-height', 0);

  useEffect(() => {
    if (embedded === 'true') {
      setIsEmbedded(true);
    }
  }, [embedded]);

  useEffect(() => {
    if (statusBarHeight) {
      setHeight(Number(statusBarHeight));
    }
  }, [statusBarHeight]);

  return (
    <WebviewContext.Provider
      value={{
        isEmbedded,
        statusBarHeight: height,
      }}
    >
      {children}
    </WebviewContext.Provider>
  );
};

export { WebviewContext, WebviewProvider };
