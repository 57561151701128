import * as Yup from 'yup';
import { Typography } from '@mui/material';
import ProfileForm from './ProfileForm';

const ProfileFormTitle = () => (
  <>
    <Typography variant="h3" gutterBottom component="div">
      Créez votre profil
    </Typography>
  </>
);

const ProfileFormSubtitle = () => (
  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
    Veuillez renseignez vos informations
  </Typography>
);

const getProfileFormConfiguration = () => {
  const schema = {
    firstName: Yup.string().required('Le prénom est requis'),
    lastName: Yup.string().required('Le nom est requis'),
    avatar: Yup.string().nullable(),
    avatarFile: Yup.mixed().nullable(),
  };

  const defaultValues = {
    firstName: '',
    lastName: '',
    avatar: null,
    avatarFile: null,
  };

  return {
    schema,
    defaultValues,
    FormComponent: ProfileForm,
    TitleComponent: ProfileFormTitle,
    SubtitleComponent: ProfileFormSubtitle,
    submitButtonLabel: 'Valider',
  };
};

export default getProfileFormConfiguration;
