import { ButtonBase, Stack, Typography } from '@mui/material';
import React from 'react';
import Image from '../../../../components/Image';
import config from '../../../../config/index';

const { mobileApplicationUrl } = config;

const getOs = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'other';
};

const imageButton = {
  ios: '/assets/illustrations/download_app_ios.svg',
  android: '/assets/illustrations/download_app_android.png',
  other: '/assets/illustrations/download_app_ios.svg',
};

const DownloadAppButton = () => {
  const os = getOs();

  return (
    <Stack spacing={1} alignItems="center" justifyContent="center">
      <Typography variant="caption">Téléchargez l'application sur les stores</Typography>
      <Stack spacing={1} direction="row">
        <ButtonBase
          href={mobileApplicationUrl}
          target="_blank"
          sx={{ borderRadius: (theme) => theme.shape.borderRadius }}
        >
          <Image sx={{ flex: 1 }} src={imageButton[os]} />
        </ButtonBase>
      </Stack>
    </Stack>
  );
};

export default DownloadAppButton;
