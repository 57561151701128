import { Box, Typography, Stack } from '@mui/material';

const ActivateNotificationsFormTitle = () => (
  <Stack spacing={4} alignItems="center">
    <Stack
      sx={{
        borderRadius: '50%',
        border: (theme) => `3px solid ${theme.palette.primary.main}`,
        width: 50,
        height: 50,
        p: 1,
      }}
    >
      <Box
        sx={{
          width: 30,
          height: 30,
        }}
        component="img"
        src="/assets/camera.svg"
      />
    </Stack>
    <Typography variant="h3" gutterBottom component="div">
      Activez les alertes
    </Typography>
  </Stack>
);

const ActivateNotificationsFormSubtitle = () => (
  <Typography variant="body2" textAlign="center" sx={{ color: 'text.secondary', maxWidth: 280 }}>
    Autoriser les notifications pour être informé des suspicions de vol en direct
  </Typography>
);

const getActivateNotificationsFormConfiguration = () => ({
  schema: {},
  defaultValues: {},
  FormComponent: null,
  TitleComponent: ActivateNotificationsFormTitle,
  SubtitleComponent: ActivateNotificationsFormSubtitle,
  submitButtonLabel: 'Activer',
});

export default getActivateNotificationsFormConfiguration;
