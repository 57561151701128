import { useSnackbar } from 'notistack';
import { Card, Stack, Typography, Drawer, Box } from '@mui/material';

const COLLABORATOR_ACTIONS = [
  {
    label: 'Copier le lien d’invitation',
    icon: '/assets/profile/copy.svg',
    action: async ({ onClose, enqueueSnackbar, linkToCopy }) => {
      await window.navigator.clipboard.writeText(linkToCopy);
      enqueueSnackbar('Lien copié dans le presse-papier');
      onClose();
    },
  },
  {
    label: 'Faire scanner le QR Code',
    icon: '/assets/profile/frame-square.svg',
    action: ({ onScanStoreQRCodeOpen, onClose }) => {
      onScanStoreQRCodeOpen();
      onClose();
    },
  },
];

const StoresActionsDrawer = ({ store, onClose, onScanStoreQRCodeOpen }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Drawer
        anchor="bottom"
        open
        onClose={onClose}
        sx={{
          zIndex: 9999,
          '.MuiDrawer-paper': {
            borderRadius: '16px 16px 0 0',
          },
        }}
      >
        <Card sx={{ p: (theme) => theme.spacing(4, 2, 6, 2) }}>
          <Stack alignItems="center" mb={4} spacing={1}>
            <Stack alignItems="center">
              <Typography variant="h3" textAlign="center">
                Inviter des collaborateurs
              </Typography>

              <Typography
                sx={{
                  color: '#9B9B9B',
                }}
              >
                Choisissez une méthode
              </Typography>
              <Typography variant="body" sx={{ color: '#9B9B9B' }}>
                {store.name.toUpperCase()}
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={1}>
            {COLLABORATOR_ACTIONS.map((action) => (
              <Card
                key={action.label}
                onClick={() =>
                  action.action({ onClose, enqueueSnackbar, linkToCopy: store.invitationUrl, onScanStoreQRCodeOpen })
                }
                sx={{
                  cursor: 'pointer',
                  p: 2,
                  borderRadius: '8px',
                  border: 'none',
                  backgroundColor: (theme) => action.backgroundColor || theme.palette.background.paper,
                }}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    component="img"
                    src={action.icon}
                    sx={{
                      width: 24,
                      height: 24,
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 500,
                      color: action.color,
                    }}
                  >
                    {action.label}
                  </Typography>
                </Stack>
              </Card>
            ))}
          </Stack>
        </Card>
      </Drawer>
    </>
  );
};

export default StoresActionsDrawer;
