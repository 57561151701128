import { useLazyQuery, useMutation } from '@apollo/client';
import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { CURRENT_USER, MY_STORES } from '../graphql/users/queries';
import useDialogs from '../hooks/useDialogs';
import { PATH_DASHBOARD } from '../routes/paths';
import { wsLink } from '../services/apollo-client';
import { setSession } from '../utils/jwt';
import USER_ROLES from '../constants/userRoles';
import useQueryParams from '../hooks/useQueryParams';
import { SAVE_DEVICE } from '../graphql/device/mutations';
import getDeviceInformation from '../utils/getDeviceInformation';

const AuthContext = createContext({
  logout: () => Promise.resolve(),
});

const AuthProvider = ({ children }) => {
  const { alert } = useDialogs();
  const { accessToken } = useQueryParams();
  const { type, identifier, name } = getDeviceInformation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedStore, setSelectedStore] = useState();

  const navigate = useNavigate();

  const onFetchError = () => {
    setSession(null);
    setIsAuthenticated(false);
    setIsInitialized(true);
  };

  const [saveDevice] = useMutation(SAVE_DEVICE, {
    variables: {
      input: {
        identifier,
        type,
        name,
        isMuted: true,
      },
    },
  });

  const [getMyStores, { data: storesData, refetch: refetchGetMyStores }] = useLazyQuery(MY_STORES, {
    nextFetchPolicy: 'network-only',
    onCompleted: () => {
      // Force socket reconnection
      wsLink.subscriptionClient.close(true);
      setIsAuthenticated(true);
      setIsInitialized(true);
    },
    onError: onFetchError,
    refetchWritePolicy: 'overwrite',
  });

  const [getMe, { data: userData, refetch: refetchMe }] = useLazyQuery(CURRENT_USER, {
    nextFetchPolicy: 'network-only',
    onCompleted: getMyStores,
    onError: onFetchError,
    refetchWritePolicy: 'overwrite',
  });

  useEffect(() => {
    const initialize = async () => {
      const token = accessToken || window.localStorage.getItem('token');

      if (token) {
        setSession(token);
        getMe();
      } else {
        setIsInitialized(true);
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoginSuccess = (token) => {
    setSession(token);
    refetchMe();
  };

  const logout = async () => {
    await saveDevice();
    setSession(null);
    setIsAuthenticated(false);
    setSelectedStore();
    window.location.reload();
  };

  const expireUser = async () => {
    await alert({
      message: 'Vous avez été déconnecté',
      title: 'Session terminée',
    });
    //
    logout();
  };

  const expireUserAfterSuspension = () => {
    setSession(null);
    setIsAuthenticated(false);
    setSelectedStore();
    window.location.reload();
  };

  const onChangeStore = (store) => {
    if (!store) return;
    navigate(PATH_DASHBOARD.stores.view(store.id));
  };

  return (
    <AuthContext.Provider
      value={{
        stores: storesData?.myStores,
        expireUser,
        expireUserAfterSuspension,
        isAuthenticated,
        isInitialized,
        logout,
        onLoginSuccess,
        refetchGetMyStores,
        user: userData?.me,
        onChangeStore,
        selectedStore,
        setSelectedStore,
        isReady: userData?.me?.role === USER_ROLES.ADMIN || Boolean(storesData?.myStores?.length),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
