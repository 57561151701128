import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import Page from 'src/components/Page';
import getPasswordFormConfiguration from 'src/sections/auth/login/password/getPasswordFormConfiguration';
import getEmailFormConfiguration from 'src/sections/auth/shared/getEmailFormConfiguration';
import useSafeMutation from 'src/services/apollo-client/wrappers/useSafeMutation';
import { LOGIN } from 'src/graphql/users/mutations';
import useAuth from 'src/hooks/useAuth';
import Logo from 'src/components/Logo';
import BackArrowIcon from 'src/components/BackArrow';
import FormErrors from 'src/components/form/FormErrors';
import expectedErrors from 'src/graphql/users/expectedErrors';
import useResponsive from 'src/hooks/useResponsive';
import getDeviceInformation from 'src/utils/getDeviceInformation';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled(Stack)(({ theme, isDesktop }) => ({
  padding: theme.spacing(8, isDesktop ? 6 : 2),
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: isDesktop ? 'center' : 'flex-start',
  justifyContent: isDesktop ? 'center' : 'flex-start',
  maxWidth: 480,
}));

const useSteps = () => {
  const maxSteps = 2;
  const [step, setStep] = useState(0);
  const isLastStep = step + 1 === maxSteps;
  const isFirstStep = step === 0;

  const nextStep = () => {
    if (!isLastStep) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (!isFirstStep) {
      setStep(step - 1);
    }
  };

  return { step, nextStep, prevStep, isLastStep, isFirstStep };
};

const Login = () => {
  const { onLoginSuccess } = useAuth();
  const isDesktop = useResponsive('up', 'md');
  const { identifier } = getDeviceInformation();

  const { step, nextStep, prevStep, isFirstStep, isLastStep } = useSteps();

  const [login, { loading: loginLoading, error: loginError }] = useSafeMutation(LOGIN, {
    onCompleted: ({ login: result }) => {
      onLoginSuccess(result.token);
    },
  });
  const loginExpectedErrors = [expectedErrors.INVALID_CREDENTIALS, expectedErrors.USER_NOT_FOUND];

  const LOGIN_FORM_STEPS = useMemo(
    () => [
      getEmailFormConfiguration({
        isDesktop,
      }),
      getPasswordFormConfiguration(),
    ],
    [isDesktop]
  );

  const mergedSchemasDependingOnStep = LOGIN_FORM_STEPS.reduce((acc, { schema }, index) => {
    if (index <= step) {
      return { ...acc, ...schema };
    }
    return acc;
  }, {});

  const mergedDefaultValues = LOGIN_FORM_STEPS.reduce((acc, { defaultValues }, index) => {
    if (index <= step) {
      return { ...acc, ...defaultValues };
    }
    return acc;
  }, {});

  const {
    TitleComponent,
    SubtitleComponent,
    FormComponent,
    submitButtonLabel,
    stepOperation,
    AdditionalComponentAction,
  } = LOGIN_FORM_STEPS[step];
  const hasOperation = !!stepOperation;

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(Yup.object().shape(mergedSchemasDependingOnStep)),
    defaultValues: mergedDefaultValues,
  });

  const { formState, watch } = methods;
  const { isValid } = formState;
  const formValues = watch();
  const canSubmit = isValid;

  useEffect(() => {
    methods.reset(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const onLoginSubmit = async (formValues) => {
    try {
      await login({
        variables: {
          email: formValues.email,
          password: formValues.password,
          identifier,
        },
      });
    } catch {
      // Nothing to do
    }
  };

  return (
    <Page title="Login">
      <RootStyle>
        {isDesktop && (
          <Logo
            sx={{
              position: 'absolute',
              top: (theme) => theme.spacing(3),
              left: (theme) => theme.spacing(3),
            }}
          />
        )}
        <ContentStyle isDesktop={isDesktop}>
          {!isFirstStep && (
            <Box onClick={prevStep} sx={{ cursor: 'pointer', alignSelf: 'flex-start' }}>
              <BackArrowIcon />
            </Box>
          )}

          <Stack
            sx={{
              mb: 4,
            }}
          >
            {isFirstStep && !isDesktop && <Logo sx={{ mb: 8 }} />}
            <TitleComponent />
            <SubtitleComponent />
          </Stack>

          <Stack
            sx={{
              mb: isDesktop ? 3 : 8,
              width: '100%',
            }}
            spacing={2}
          >
            <FormProvider {...methods}>
              <Stack spacing={2}>
                <FormErrors error={loginError} expectedErrors={loginExpectedErrors} />
                <FormComponent formValues={formValues} />
              </Stack>
            </FormProvider>
          </Stack>

          <Stack spacing={3} sx={{ width: '100%' }}>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              onClick={() => {
                if (isLastStep) {
                  onLoginSubmit(formValues);
                }
                if (!hasOperation) nextStep();
                if (hasOperation)
                  stepOperation({
                    variables: {
                      email: formValues.email,
                    },
                  });
              }}
              loading={loginLoading}
              disabled={!canSubmit}
            >
              {submitButtonLabel}
            </LoadingButton>
            {AdditionalComponentAction && <AdditionalComponentAction />}
          </Stack>
        </ContentStyle>
      </RootStyle>
    </Page>
  );
};

export default Login;
