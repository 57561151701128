import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';

const PromptDialog = ({ message, onConfirm, onDiscard, open, title }) => {
  const [prompt, setPrompt] = useState();

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogContent>
        <TextField
          fullWidth
          multiline
          onChange={(e) => {
            setPrompt(e.target.value);
          }}
          rows={3}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDiscard}>Annuler</Button>
        <Button onClick={() => onConfirm(prompt)} variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromptDialog;
