import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import commonExpectedErrors from '../../graphql/common/expectedErrors';
import getSafeArray from '../../utils/getSafeArray';

const FormErrors = ({ error, expectedErrors, withChallenge, withScrollToTop }) => {
  const [lastError, setLastError] = useState(false);
  const defaultErrorMessage = "Quelque chose s'est mal passé";
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (!error) setLastError(null);
  }, [error]);

  useEffect(() => {
    if (error) {
      const { graphQLErrors, networkError } = error;
      let errorHandled;
      if (networkError) {
        networkError.result?.errors?.forEach(({ message }) => {
          errorHandled = true;
          setErrorMessage(message);
        });
        /** the networkError.result?.errors?.forEach loop should be done here */
        // handle the critical "Request header too large" error even if silentError is true
        if (networkError.result?.error?.code === 494) {
          errorHandled = true;
          //
        }
        if (!errorHandled) {
          errorHandled = true;
          // extract errorMessage in custom NGINX responses if applicable
          setErrorMessage(networkError.result?.error?.message || networkError.message);
        }
      }
      if (graphQLErrors) {
        if (withChallenge) {
          const challengeError = graphQLErrors.find(
            ({ extensions }) => extensions?.code === commonExpectedErrors.CHALLENGE_REQUIRED.code
          );
          if (challengeError) {
            errorHandled = true;
            const challengeType = challengeError?.extensions?.challengeType;
            setErrorMessage(commonExpectedErrors.CHALLENGE_REQUIRED.message({ challengeType }));
            return;
          }
          const noChallengeAssociatedError = graphQLErrors.find(
            ({ extensions }) => extensions?.code === commonExpectedErrors.NO_CHALLENGE_ASSOCIATED.code
          );
          if (noChallengeAssociatedError) {
            errorHandled = true;
            setErrorMessage(commonExpectedErrors.NO_CHALLENGE_ASSOCIATED.message);
            return;
          }
        }
        graphQLErrors.forEach(({ extensions }) => {
          const safeExpectedErrors = getSafeArray(expectedErrors);
          const expectedError = safeExpectedErrors.find((e) => e.code === extensions?.code);
          errorHandled = true;
          setErrorMessage(expectedError?.message || defaultErrorMessage);
          if (withScrollToTop) window.scrollTo({ behavior: 'smooth', top: 0 });
          if (expectedError?.on && expectedError?.code !== lastError?.code) {
            setLastError(expectedError);
            expectedError.on();
          }
        });
      }
    }
  }, [expectedErrors, error, defaultErrorMessage, withChallenge, withScrollToTop, lastError]);

  if (error) return <Alert severity="error">{errorMessage}</Alert>;

  return null;
};

export default FormErrors;
