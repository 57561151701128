import * as Yup from 'yup';
import { Typography } from '@mui/material';
import PasswordsForm from './PasswordsForm';

const PasswordsFormTitle = () => (
  <>
    <Typography variant="h3" gutterBottom component="div">
      Bienvenue sur Alfred
    </Typography>
  </>
);

const PasswordsFormSubtitle = () => (
  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
    Veuillez créer votre mot de passe
  </Typography>
);

const getPasswordsFormConfiguration = () => {
  const schema = {
    password: Yup.string()
      .min(6, 'Le mot de passe doit contenir au moins 6 caractères')
      .required('Le mot de passe est requis'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Les mots de passe doivent correspondre')
      .required('La confirmation du mot de passe est requise'),
  };
  const defaultValues = {
    password: '',
    passwordConfirmation: '',
  };

  return {
    schema,
    defaultValues,
    FormComponent: PasswordsForm,
    TitleComponent: PasswordsFormTitle,
    SubtitleComponent: PasswordsFormSubtitle,
    submitButtonLabel: 'Valider',
  };
};

export default getPasswordsFormConfiguration;
