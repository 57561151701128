import React, { useState } from 'react';
import { Button, IconButton, InputAdornment, Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';
import { REQUEST_RESET_PASSWORD } from 'src/graphql/users/mutations';
import { RHFTextField } from '../../../../components/hook-form';
import Iconify from '../../../../components/Iconify';

export default function PasswordForm() {
  const [showPassword, setShowPassword] = useState(false);
  const { watch } = useFormContext();
  const formValues = watch();
  const { enqueueSnackbar } = useSnackbar();

  const [requestResetPassword] = useSafeMutation(REQUEST_RESET_PASSWORD, {
    onCompleted: () => {
      enqueueSnackbar('Un email vous a été envoyé');
    },
  });

  const onSubmit = async ({ email }) => {
    try {
      requestResetPassword({
        variables: {
          email,
        },
      });
    } catch {
      // Nothing to do here
    }
  };

  return (
    <Box>
      <RHFTextField
        name="password"
        label="Mot de passe"
        type={showPassword ? 'text' : 'password'}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="text"
        sx={{
          color: 'primary.main',
          fontWeight: 'normal',
          fontSize: '0.75rem',
          textTransform: 'none',
          minWidth: 'fit-content',
          px: 0,
        }}
        onClick={() => {
          onSubmit(formValues);
        }}
      >
        Mot de passe oublié
      </Button>
    </Box>
  );
}
