// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ user, ...other }) {
  const { user: loggedUser } = useAuth();
  const safeUser = user || loggedUser;

  return (
    <Avatar
      src={safeUser?.avatarUrl}
      alt={safeUser?.firstName}
      color={safeUser?.photoURL ? 'default' : createAvatar(safeUser?.firstName).color}
      {...other}
    >
      {createAvatar(safeUser?.firstName).name}
    </Avatar>
  );
}
