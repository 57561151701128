import { Stack } from '@mui/material';
import CollaboratorItem from 'src/sections/profile/collaborators/CollaboratorItem';

const CollaboratorsList = ({ collaborators }) => (
  <Stack>
    {collaborators.map((collaborator) => (
      <>
        <CollaboratorItem collaborator={collaborator} key={collaborator.id} />
      </>
    ))}
  </Stack>
);

export default CollaboratorsList;
