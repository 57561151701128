import { useEffect } from 'react';
import useResponsive from 'src/hooks/useResponsive';

const useBlockHorizontalScroll = () => {
  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    if (isMobile) {
      document.body.style.overflowX = 'hidden';
    } else {
      document.body.style.overflowX = '';
    }

    return () => {
      document.body.style.overflowX = '';
    };
  }, [isMobile]);
};

export default useBlockHorizontalScroll;
