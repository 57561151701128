import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
//
import Logo from './Logo';

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

const pulseAnimation = {
  scale: [1, 1.1, 1],
  transition: {
    duration: 1,
    repeat: Infinity,
    repeatType: 'loop',
  },
};

LoadingScreen.propTypes = {
  isDashboard: PropTypes.bool,
};

export default function LoadingScreen({ isDashboard, ...other }) {
  return (
    <>
      {!isDashboard && (
        <RootStyle {...other}>
          <m.div animate={pulseAnimation}>
            <Logo />
          </m.div>
        </RootStyle>
      )}
    </>
  );
}
