import { styled } from '@mui/material/styles';
import { AppBar } from '@mui/material';
import cssStyles from '../../../utils/cssStyles';
import { HEADER, NAVBAR } from '../../../config';

import MenuAppBar from './MenuAppBar';

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' &&
    prop !== 'isOffset' &&
    prop !== 'verticalLayout' &&
    prop !== 'statusBarHeight' &&
    prop !== 'isEmbedded',
})(({ isCollapse, isOffset, verticalLayout, theme, statusBarHeight }) => ({
  ...cssStyles(theme).bgBlur({
    backgroundColor: theme.palette.background.paper,
    opacity: 1,
  }),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT + Number(statusBarHeight),
  zIndex: theme.zIndex.appBar,
  bottom: 0,
  top: 'auto',
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

const DashboardHeader = () => (
  <RootStyle>
    <MenuAppBar />
  </RootStyle>
);

export default DashboardHeader;
