import Iconify from './Iconify';

const BackArrowIcon = () => (
  <Iconify
    icon="eva:arrow-back-outline"
    sx={{
      height: 26,
      width: 26,
    }}
  />
);

export default BackArrowIcon;
