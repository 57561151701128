import { gql } from '@apollo/client';

const LIST_STORES = gql`
  query listStores($filters: FiltersStoresInput!, $pagination: Pagination!) {
    listStores(filters: $filters, pagination: $pagination) {
      total
      hasMore
      stores {
        id
        code
        name
        inactive
        hasValidation
        createdAt
      }
    }
  }
`;

const GET_STORE = gql`
  query getStore($id: ID!) {
    getStore(id: $id) {
      id
      code
      name
      invitationUrl
      inactive
      hasValidation
      createdAt
    }
  }
`;

const GET_STORE_NAME = gql`
  query getStoreName($invitationCode: String!) {
    getStoreName(invitationCode: $invitationCode)
  }
`;

export { LIST_STORES, GET_STORE, GET_STORE_NAME };
