import * as Yup from 'yup';
import { Typography } from '@mui/material';
import PasswordForm from './PasswordForm';

const PasswordFormTitle = () => (
  <>
    <Typography variant="h3" gutterBottom component="div">
      Connexion
    </Typography>
  </>
);

const PasswordFormSubtitle = () => (
  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
    Veuillez renseigner votre mot de passe pour vous authentifier
  </Typography>
);

const getPasswordFormConfiguration = () => {
  const schema = {
    password: Yup.string().required('Le mot de passe est requis'),
  };

  const defaultValues = {
    password: '',
  };

  return {
    schema,
    defaultValues,
    FormComponent: PasswordForm,
    TitleComponent: PasswordFormTitle,
    SubtitleComponent: PasswordFormSubtitle,
    submitButtonLabel: 'Valider',
  };
};

export default getPasswordFormConfiguration;
