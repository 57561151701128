const expectedErrors = {
  ACCOUNT_SUSPENDED: {
    code: 'ACCOUNT_SUSPENDED',
  },
  INVALID_CREDENTIALS: {
    code: 'INVALID_CREDENTIALS',
    message: 'Les identifiants saisis sont invalides',
  },
  USER_NOT_FOUND: {
    code: 'USER_NOT_FOUND',
    message: "L'utilisateur n'existe pas",
  },
  INVALID_INVITATION_LINK: {
    code: 'INVALID_INVITATION_LINK',
    message: "Un problème s'est produit. L'invitation est erronée ou invalide.",
  },
  INVALID_CODE: {
    code: 'INVALID_CODE',
    message: 'Le code de sécurité saisi est invalide',
  },
};

export default expectedErrors;
