function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  signUp: path(ROOTS_AUTH, '/sign-up'),
};

export const PATH_PAGE = {
  page404: '/404',
};

export const PATH_DASHBOARD = {
  root: path(ROOTS_DASHBOARD, '/'),
  home: path(ROOTS_DASHBOARD, '/home'),
  profile: path(ROOTS_DASHBOARD, '/profile'),
  stores: {
    list: path(ROOTS_DASHBOARD, '/stores'),
    view: (id) => path(ROOTS_DASHBOARD, `/stores/${id}`),
  },
  alerts: {
    list: path(ROOTS_DASHBOARD, '/alerts'),
    view: (id) => path(ROOTS_DASHBOARD, `/alerts/${id}`),
  },
  contacts: path(ROOTS_DASHBOARD, '/contacts'),
  invitations: (invitationCode) => path(ROOTS_DASHBOARD, `/invitations/${invitationCode}`),
  collaborators: {
    register: path(ROOTS_DASHBOARD, '/collaborators/register'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
