// routes
import Router from './routes';

import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import useBlockHorizontalScroll from './hooks/useBlockHorizontalScroll';

export default function App() {
  useBlockHorizontalScroll();

  return (
    <MotionLazyContainer>
      <ProgressBarStyle />
      <ChartStyle />
      <ScrollToTop />
      <Router />
    </MotionLazyContainer>
  );
}
