import { Box, Card, Stack, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';

const STEPS_TO_REGISTER = [
  {
    label: 'Scanner le QR Code',
  },
  {
    label: 'Télécharger l’application Alfred.ai',
  },
  {
    label: 'S’inscrire via email',
  },
];

const DetailedStoreQRCodeStepsDrawer = ({ store }) => (
  <Stack spacing={8} alignItems="center">
    <Card
      sx={{
        p: 4,
        borderRadius: (theme) => theme.spacing(4),
        display: 'flex',
        justifyContent: 'center',
        width: 'fit-content',
      }}
    >
      <QRCodeSVG value={store.invitationUrl} size={250} />
    </Card>
    <Stack
      spacing={1}
      sx={{
        width: '100%',
      }}
    >
      {STEPS_TO_REGISTER.map((step, index) => (
        <Card
          key={step.label}
          sx={{
            p: 2,
            borderRadius: '8px',
            border: 'none',
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box
              p={2}
              sx={{
                borderRadius: '50%',
                backgroundColor: '#000000',
                color: (theme) => theme.palette.primary.contrastText,
                width: 16,
                height: 16,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption">{index + 1}</Typography>
            </Box>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {step.label}
            </Typography>
          </Stack>
        </Card>
      ))}
    </Stack>
  </Stack>
);

export default DetailedStoreQRCodeStepsDrawer;
