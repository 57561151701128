import { Card, Stack, Typography, Drawer, Box } from '@mui/material';
import { useSnackbar } from 'notistack';

import { DELETE_USER, SAVE_USER } from '../../../graphql/users/mutations';
import useSafeMutation from '../../../services/apollo-client/wrappers/useSafeMutation';
import USER_ROLES from '../../../constants/userRoles';
import useAuth from '../../../hooks/useAuth';

const CollaboratorDrawerActions = ({ collaborator, onClose }) => {
  const { refetchGetMyStores } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [saveUser] = useSafeMutation(SAVE_USER, {
    refetchQueries: ['listUsers'],
    onCompleted: () => {
      refetchGetMyStores();
      onClose();
      enqueueSnackbar('Collaborateur mis à jour');
    },
  });

  const [deleteUser] = useSafeMutation(DELETE_USER, {
    refetchQueries: ['listUsers'],
    onCompleted: () => {
      refetchGetMyStores();
      onClose();
      enqueueSnackbar('Collaborateur mis à jour');
    },
  });

  const handleSaveUser = async (data) => {
    try {
      await saveUser({
        variables: {
          input: {
            ...data,
          },
        },
      });
    } catch {
      // Nothing to do
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser({
        variables: {
          id,
        },
      });
    } catch {
      // Nothing to do
    }
  };

  const COLLABORATOR_ACTIONS = [
    {
      label: collaborator.role === USER_ROLES.ADMIN ? "Retirer l'administrateur" : 'Passer en administrateur',
      icon: '/assets/profile/administrator.svg',
      onClick: () =>
        handleSaveUser({
          id: collaborator.id,
          role: collaborator.role === USER_ROLES.ADMIN ? USER_ROLES.EMPLOYEE : USER_ROLES.ADMIN,
        }),
    },
    {
      label: collaborator.inactive ? "Réactiver l'accès" : "Suspendre l'accès",
      icon: '/assets/profile/suspend.svg',
      onClick: () =>
        handleSaveUser({
          id: collaborator.id,
          inactive: !collaborator.inactive,
        }),
    },
    {
      label: 'Supprimer l’accès',
      icon: '/assets/profile/stop.svg',
      color: '#E81A1A',
      onClick: () => handleDeleteUser(collaborator.id),
    },
  ];

  return (
    <Drawer
      anchor="bottom"
      open
      onClose={onClose}
      sx={{
        zIndex: 9999,
        '.MuiDrawer-paper': {
          borderRadius: '16px 16px 0 0',
        },
      }}
    >
      <Card
        sx={{
          p: (theme) => theme.spacing(4, 2, 6, 2),
        }}
      >
        <Stack spacing={1}>
          {COLLABORATOR_ACTIONS.map((action) => (
            <Card
              key={action.label}
              sx={{
                p: 2,
                borderRadius: '8px',
                border: 'none',
                cursor: 'pointer',
                backgroundColor: (theme) => action.backgroundColor || theme.palette.background.paper,
              }}
              onClick={action.onClick}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box
                  component="img"
                  src={action.icon}
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 500,
                    color: action.color,
                  }}
                >
                  {action.label}
                </Typography>
              </Stack>
            </Card>
          ))}
        </Stack>
      </Card>
    </Drawer>
  );
};

export default CollaboratorDrawerActions;
