import { gql } from '@apollo/client';

const SEND_TEST_ALERT = gql`
  mutation sendTestAlert($deviceId: ID!) {
    sendTestAlert(deviceId: $deviceId)
  }
`;

const ATTACH_TAGS = gql`
  mutation attachTags($alertId: ID!, $tagsTypeId: ID!, $tags: [SaveTagInput!]!) {
    attachTags(alertId: $alertId, tagsTypeId: $tagsTypeId, tags: $tags) {
      id
      tags {
        id
        label
        tagsTypeId
      }
      falsePositive
      externalId
      storeId
      cameraId
      title
      body
      validated
      videoUrl
      imageUrl
      occurredAt
    }
  }
`;

const EVALUATE_ALERT = gql`
  mutation evaluateAlert($alertId: ID!, $input: EvaluateAlertInput!) {
    evaluateAlert(alertId: $alertId, input: $input) {
      id
      tags {
        id
        label
        tagsTypeId
      }
      falsePositive
      externalId
      storeId
      cameraId
      title
      body
      videoUrl
      imageUrl
      validated
      occurredAt
      type
    }
  }
`;

const DISPATCH_ALERT = gql`
  mutation dispatchAlert($id: ID!) {
    dispatchAlert(id: $id) {
      id
      falsePositive
      externalId
      storeId
      cameraId
      title
      body
      videoUrl
      imageUrl
      validated
      occurredAt
    }
  }
`;

const DELETE_ALERT = gql`
  mutation deleteAlert($id: ID!) {
    deleteAlert(id: $id)
  }
`;

const SET_FAVORITE = gql`
  mutation setFavorite($alertId: ID!) {
    setFavorite(alertId: $alertId) {
      id
      isFavorite
    }
  }
`;

export { SEND_TEST_ALERT, ATTACH_TAGS, EVALUATE_ALERT, DISPATCH_ALERT, DELETE_ALERT, SET_FAVORITE };
