import UserAvatar from 'src/sections/shared/UserAvatar';
import { RHFTextField } from '../../../../components/hook-form';

const ProfileForm = () => (
  <>
    <UserAvatar />
    <RHFTextField name="firstName" label="Prénom" type="text" placeholder="Prénom" required />
    <RHFTextField name="lastName" label="Nom" placeholder="Nom" required />
  </>
);

export default ProfileForm;
