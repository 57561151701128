// ----------------------------------------------------------------------

export default function Link(theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          color: `${theme.palette.text.tertiary} !important`,
          textDecoration: 'underline',
          fontWeight: theme.typography.fontWeightMedium,
          textDecorationColor: `${theme.palette.text.tertiary} !important`,
          '&:hover': {
            color: `${theme.palette.text.tertiary} !important`,
            textDecorationColor: theme.palette.text.tertiary,
          },
        },
      },
      defaultProps: {
        underline: 'hover',
      },
    },
  };
}
