import { useState } from 'react';
import { Button, Dialog, Divider, IconButton, OutlinedInput, Stack, Toolbar, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import stopPropagation from 'src/utils/stopPropagation';
import EmptyContent from 'src/components/EmptyContent';
import useAuth from 'src/hooks/useAuth';
import CollaboratorsList from './CollaboratorsList';
import StoresActionsDrawer from '../stores/StoresActionsDrawer';
import StoresQRCodesDrawer from '../../shared/StoresQRCodesDrawer';

const CollaboratorsStoreSection = ({ stores, searchQuery }) => {
  const [openStoresActionsDrawer, setOpenStoresActionsDrawer] = useState(false);
  const [wantsToScanStoreQRCode, setWantsToScanStoreQRCode] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);

  const handleStoresActionsDrawerOpen = () => {
    setOpenStoresActionsDrawer(true);
  };
  const handleStoresActionsDrawerClose = () => {
    setOpenStoresActionsDrawer(false);
  };
  const handleWantsToScanStoreQRCodeOpen = () => {
    setWantsToScanStoreQRCode(true);
  };
  const handleWantsToScanStoreQRCodeClose = () => {
    setWantsToScanStoreQRCode(false);
  };
  const handleStoreSelection = (store) => {
    setSelectedStore(store);
    handleStoresActionsDrawerOpen();
  };

  return (
    <>
      {stores.map((store, index) => {
        const isLast = index === stores.length - 1;
        return (
          <Stack key={store.id} spacing={2} mb={4}>
            <Stack direction="row">
              <Stack sx={{ flex: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  {store.name.toUpperCase()}
                </Typography>
                <Typography
                  variant="body"
                  sx={{ color: '#8F8F8F', wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}
                >
                  {store.code}
                </Typography>
              </Stack>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleStoreSelection(store)}
                sx={{
                  textTransform: 'none',
                  borderRadius: (theme) => theme.spacing(8),
                  height: (theme) => theme.spacing(4),
                }}
                startIcon={<Iconify icon="mdi:plus" />}
              >
                Inviter
              </Button>
            </Stack>
            <CollaboratorsList
              collaborators={store.collaborators.filter((collaborator) =>
                `${collaborator.firstName} ${collaborator.lastName}`.toLowerCase().includes(searchQuery)
              )}
            />
            {!isLast && <Divider sx={{ background: '#ECECEC' }} />}
          </Stack>
        );
      })}
      {openStoresActionsDrawer && (
        <StoresActionsDrawer
          onClose={handleStoresActionsDrawerClose}
          store={selectedStore}
          onScanStoreQRCodeOpen={handleWantsToScanStoreQRCodeOpen}
        />
      )}
      {wantsToScanStoreQRCode && (
        <StoresQRCodesDrawer onClose={handleWantsToScanStoreQRCodeClose} store={selectedStore} />
      )}
    </>
  );
};

const CollaboratorsDialog = ({ onClose, isDesktop }) => {
  const { stores } = useAuth();
  const [searchQuery, setSearchQuery] = useState('');

  const filteredStores = stores.filter((store) =>
    store.collaborators.some((collaborator) =>
      `${collaborator.firstName} ${collaborator.lastName}`.toLowerCase().includes(searchQuery)
    )
  );

  const hasStoresResults = filteredStores.length > 0;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleResetSearch = () => {
    setSearchQuery('');
  };
  return (
    <Dialog
      open
      fullScreen={!isDesktop}
      fullWidth={isDesktop}
      onClose={stopPropagation(onClose)}
      sx={{
        '.MuiDialog-paper': {
          padding: 0,
          margin: 0,
          p: (theme) => theme.spacing(6, 4),
          backgroundColor: 'white',
        },
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          sx={{
            position: 'absolute',
            left: 0,
          }}
          onClick={stopPropagation(onClose)}
        >
          <Iconify icon="eva:arrow-back-outline" sx={{ height: 26, width: 26 }} />
        </IconButton>
        <Typography variant="h6">Mes collaborateurs</Typography>
      </Toolbar>
      <OutlinedInput
        type="text"
        placeholder="Rechercher"
        onChange={handleSearchChange}
        value={searchQuery}
        startAdornment={
          <Iconify icon={'eva:search-fill'} sx={{ width: 24, height: 24, mx: (theme) => theme.spacing(1) }} />
        }
        sx={{
          mb: 4,
          borderRadius: '50px !important',
          backgroundColor: '#F5F5F5',
          '& .MuiOutlinedInput-input': {
            py: 1.5,
          },
        }}
      />
      <CollaboratorsStoreSection stores={filteredStores} searchQuery={searchQuery} />
      {!hasStoresResults && (
        <EmptyContent title="Aucun résultat" description="Aucun collaborateur ne correspond à votre recherche.">
          <Button
            variant="text"
            sx={{
              color: 'primary.main',
              fontWeight: 'normal',
              fontSize: '0.75rem',
              textTransform: 'none',
              minWidth: 'fit-content',
              px: 0,
            }}
            onClick={() => {
              handleResetSearch();
            }}
          >
            Réinitialiser la recherche
          </Button>
        </EmptyContent>
      )}
    </Dialog>
  );
};

export default CollaboratorsDialog;
