import React, { useState } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Iconify from '../../../components/Iconify';

const QuestionsAnswersItem = ({ accordion }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      elevation={0}
      sx={{
        background: 'none',
        borderBottom: 1,
        borderColor: '#ECECEC',
        zIndex: 9999,
        '&:before': {
          display: 'none',
        },
        boxShadow: 'none !important',
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={
          <Iconify
            icon={expanded ? 'mdi:keyboard-arrow-up' : 'mdi:keyboard-arrow-right'}
            sx={{ ml: 0.5, width: 24, height: 24 }}
          />
        }
        sx={{
          padding: 0,
          '& .MuiAccordionSummary-content': {
            alignItems: 'center',
            margin: 0,
          },
        }}
      >
        <Typography fontWeight="bold">{accordion.question}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
          mb: 2,
        }}
      >
        <Typography>{accordion.response}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default QuestionsAnswersItem;
