import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import PasswordsForm from 'src/sections/auth/sign-up/password/PasswordsForm';
import getPasswordsFormConfiguration from 'src/sections/auth/sign-up/password/getPasswordsFormConfiguration';
import { Stack, styled } from '@mui/material';
import Page from 'src/components/Page';
import useResponsive from 'src/hooks/useResponsive';
import Logo from 'src/components/Logo';
import { LoadingButton } from '@mui/lab';
import useQueryParams from 'src/hooks/useQueryParams';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useNavigate } from 'react-router';
import { RESET_PASSWORD } from 'src/graphql/users/mutations';
import { FormProvider } from 'src/components/hook-form';

const ResetPassword = () => {
  const isDesktop = useResponsive('up', 'md');
  const { defaultValues, TitleComponent, SubtitleComponent, schema, submitButtonLabel } =
    getPasswordsFormConfiguration();
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(Yup.object().shape(schema)),
    defaultValues,
  });
  const { formState, handleSubmit } = methods;
  const { isValid } = formState;

  const canSubmit = isValid;
  const { token } = useQueryParams();
  const navigate = useNavigate();

  const [resetPassword] = useSafeMutation(
    RESET_PASSWORD,
    {
      context: {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
      onCompleted: () => {
        navigate(PATH_DASHBOARD.root, { replace: true });
      },
      onError: () => navigate(PATH_DASHBOARD.root, { replace: true }),
    },
    {
      expectExpiredLink: true,
    }
  );

  const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));

  const ContentStyle = styled(Stack)(({ theme, isDesktop }) => ({
    padding: theme.spacing(8, isDesktop ? 6 : 2),
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: isDesktop ? 'center' : 'flex-start',
    justifyContent: isDesktop ? 'center' : 'flex-start',
    maxWidth: 480,
  }));

  const onSubmit = async ({ password }) => {
    try {
      resetPassword({
        variables: {
          password,
        },
      });
    } catch {
      // Nothing to do here
    }
  };

  return (
    <Page title="Reset Password">
      <RootStyle isDesktop={isDesktop}>
        {isDesktop && (
          <Logo
            sx={{
              position: 'absolute',
              top: (theme) => theme.spacing(3),
              left: (theme) => theme.spacing(3),
            }}
          />
        )}
        <ContentStyle isDesktop={isDesktop}>
          <FormProvider methods={methods} sx={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
            <Stack
              sx={{
                mb: 4,
                width: '100%',
              }}
              spacing={1}
            >
              <TitleComponent />
              <SubtitleComponent />
            </Stack>
            <Stack
              sx={{
                mb: isDesktop ? 3 : 8,
                width: '100%',
              }}
              spacing={2}
            >
              <PasswordsForm />
            </Stack>
            <Stack
              sx={{
                width: '100%',
              }}
            >
              <LoadingButton size="large" type="submit" variant="contained" fullWidth disabled={!canSubmit}>
                {submitButtonLabel}
              </LoadingButton>
            </Stack>
          </FormProvider>
        </ContentStyle>
      </RootStyle>
    </Page>
  );
};

export default ResetPassword;
