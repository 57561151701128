import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button } from '@mui/material';
import Page from '../../components/Page';
import getEmailFormConfiguration from '../../sections/auth/shared/getEmailFormConfiguration';
import getSecurityCodeFormConfiguration from '../../sections/auth/sign-up/security-code/getSecurityCodeFormConfiguration';
import getPasswordsFormConfiguration from '../../sections/auth/sign-up/password/getPasswordsFormConfiguration';
import getProfileFormConfiguration from '../../sections/auth/sign-up/profile/getProfileFormConfiguration';
import getActivateNotificationsFormConfiguration from '../../sections/auth/sign-up/notifications/getActivateNotificationsFormConfiguration';
import useSafeMutation from '../../services/apollo-client/wrappers/useSafeMutation';
import { SEND_VERIFICATION_CODE, REGISTER_EMPLOYEE } from '../../graphql/users/mutations';
import useAuth from '../../hooks/useAuth';
import Logo from '../../components/Logo';
import BackArrowIcon from '../../components/BackArrow';
import FormErrors from '../../components/form/FormErrors';
import expectedErrors from '../../graphql/users/expectedErrors';
import useResponsive from '../../hooks/useResponsive';
import { VERIFY_CODE } from '../../graphql/users/queries';
import useSafeLazyQuery from '../../services/apollo-client/wrappers/useSafeLazyQuery';
import useQueryParams from '../../hooks/useQueryParams';
import { GET_STORE_NAME } from '../../graphql/stores/queries';
import { useSafeQuery } from '../../services/apollo-client/wrappers';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled(Stack)(({ theme, isDesktop, isLastStep }) => ({
  padding: theme.spacing(8, isDesktop ? 6 : 2),
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: isDesktop ? 'center' : 'flex-start',
  justifyContent: isDesktop ? 'center' : 'flex-start',
  maxWidth: 480,
  ...(isLastStep && {
    justifyContent: 'space-between',
  }),
}));

const useSteps = (isDesktop) => {
  const maxSteps = isDesktop ? 4 : 5;
  const [step, setStep] = useState(0);
  const isLastStep = step + 1 === maxSteps;
  const isFirstStep = step === 0;

  const nextStep = () => {
    if (!isLastStep) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (!isFirstStep) {
      setStep(step - 1);
    }
  };

  return { step, nextStep, prevStep, isLastStep, isFirstStep };
};

const SignUp = () => {
  const { onLoginSuccess } = useAuth();
  const { invitationCode, source } = useQueryParams();
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useResponsive('up', 'md');

  const { step, nextStep, prevStep, isFirstStep, isLastStep } = useSteps(isDesktop);

  const { data: storeNameData } = useSafeQuery(GET_STORE_NAME, {
    variables: { invitationCode },
  });
  const storeName = storeNameData?.getStoreName;

  const [verifyCodeOperation, { error: verifyCodeError, loading: verifyCodeLoading }] = useSafeLazyQuery(VERIFY_CODE, {
    onCompleted: () => {
      nextStep();
    },
  });

  const [sendVerificationCodeOperation, { error: sendVerificationCodeError, loading: sendVerificationCodeLoading }] =
    useSafeMutation(SEND_VERIFICATION_CODE, {
      onCompleted: () => {
        const shouldNextStep = step === 0;
        if (shouldNextStep) nextStep();
        else enqueueSnackbar('Un nouveau code a été envoyé');
      },
    });

  const [registerOperation, { loading: registerLoading }] = useSafeMutation(
    REGISTER_EMPLOYEE,
    {
      onCompleted: ({ registerEmployee }) => {
        onLoginSuccess(registerEmployee.token);
      },
    },
    {
      expectedErrors: [expectedErrors.INVALID_INVITATION_LINK],
    }
  );

  const isLoading = [verifyCodeLoading, sendVerificationCodeLoading, registerLoading].some((loading) => loading);

  const onSignUpFormSubmit = async (formValues) => {
    try {
      await registerOperation({
        variables: {
          input: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            password: formValues.password,
            avatar: formValues.avatarFile,
          },
          invitationCode,
          source,
        },
      });
    } catch {
      // Nothing to do
    }
  };

  const emailStepOperation = useMemo(
    () => ({
      operation: sendVerificationCodeOperation,
      mapVariables: (formValues) => ({
        email: formValues.email,
      }),
    }),
    [sendVerificationCodeOperation]
  );

  const securityCodeStepOperation = useMemo(
    () => ({
      operation: verifyCodeOperation,
      mapVariables: (formValues) => ({
        email: formValues.email,
        code: formValues.securityCode,
      }),
    }),
    [verifyCodeOperation]
  );

  const SIGN_UP_FORM_STEPS = useMemo(
    () =>
      [
        getEmailFormConfiguration({
          storeName,
          isDesktop,
          invitationCode,
          stepOperation: emailStepOperation,
        }),
        getSecurityCodeFormConfiguration({
          stepOperation: securityCodeStepOperation,
          additionalOperation: emailStepOperation,
        }),
        getPasswordsFormConfiguration(),
        getProfileFormConfiguration(),
        !isDesktop && getActivateNotificationsFormConfiguration(),
      ].filter((formConfiguration) => formConfiguration),
    [storeName, isDesktop, invitationCode, emailStepOperation, securityCodeStepOperation]
  );

  const EXPECTED_ERRORS = [expectedErrors.INVALID_CODE];

  const mergedSchemasDependingOnStep = SIGN_UP_FORM_STEPS.reduce((acc, { schema }, index) => {
    if (index <= step) {
      return {
        ...acc,
        ...(schema || {}),
      };
    }
    return acc;
  }, {});

  const mergedDefaultValues = SIGN_UP_FORM_STEPS.reduce((acc, { defaultValues }, index) => {
    if (index <= step) {
      return {
        ...acc,
        ...(defaultValues || {}),
      };
    }
    return acc;
  }, {});

  const {
    TitleComponent,
    SubtitleComponent,
    FormComponent,
    submitButtonLabel,
    stepOperation,
    AdditionalComponentAction,
  } = SIGN_UP_FORM_STEPS[step];
  const hasOperation = !!stepOperation;

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(Yup.object().shape(mergedSchemasDependingOnStep)),
    defaultValues: mergedDefaultValues,
  });

  const { formState, watch } = methods;
  const { isValid } = formState;
  const formValues = watch();
  const canSubmit = isValid;

  useEffect(() => {
    methods.reset(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (!storeNameData) return null;

  return (
    <Page title="Sign Up">
      <RootStyle isDesktop={isDesktop}>
        {isDesktop && (
          <Logo
            sx={{
              position: 'absolute',
              top: (theme) => theme.spacing(3),
              left: (theme) => theme.spacing(3),
            }}
          />
        )}
        <ContentStyle isDesktop={isDesktop} isLastStep={isLastStep}>
          {!isFirstStep && (
            <Box onClick={prevStep} sx={{ cursor: 'pointer', alignSelf: 'flex-start' }}>
              <BackArrowIcon />
            </Box>
          )}

          <FormProvider {...methods}>
            <Stack
              sx={{
                mb: 4,
                width: '100%',
                ...(isLastStep && {
                  alignItems: 'center',
                  justifyContent: 'center',
                }),
              }}
              spacing={1}
            >
              {isFirstStep && !isDesktop && <Logo sx={{ mb: 8 }} />}
              <TitleComponent />
              <SubtitleComponent />
            </Stack>

            {FormComponent && (
              <Stack
                sx={{
                  mb: isDesktop ? 3 : 8,
                  width: '100%',
                }}
                spacing={2}
              >
                <Stack spacing={2}>
                  <FormErrors error={verifyCodeError || sendVerificationCodeError} expectedErrors={EXPECTED_ERRORS} />
                  <FormComponent formValues={formValues} />
                </Stack>
              </Stack>
            )}
          </FormProvider>

          <Stack
            spacing={!isLastStep && 3}
            sx={{
              width: '100%',
              ...(isLastStep && {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }),
            }}
          >
            {isLastStep && (
              <Button
                variant="text"
                sx={{
                  color: 'text.secondary',
                  fontWeight: 'normal',
                  textTransform: 'none',
                }}
                fullWidth
                security="large"
                onClick={() => onSignUpFormSubmit(formValues)}
              >
                Plus tard
              </Button>
            )}
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              onClick={() => {
                if (isLastStep) {
                  onSignUpFormSubmit(formValues);
                }
                if (!hasOperation) nextStep();
                if (hasOperation)
                  stepOperation.operation({
                    variables: stepOperation.mapVariables(formValues),
                  });
              }}
              loading={isLoading}
              disabled={!canSubmit}
            >
              {submitButtonLabel}
            </LoadingButton>

            {AdditionalComponentAction && <AdditionalComponentAction />}
          </Stack>
        </ContentStyle>
      </RootStyle>
    </Page>
  );
};

export default SignUp;
