import { useState } from 'react';
import { Card, Stack, Typography, Drawer, IconButton } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import useAuth from 'src/hooks/useAuth';
import DetailedStoreQRCodeStepsDrawer from 'src/sections/shared/DetailedStoreQRCodeStepsDrawer';
import useResponsive from 'src/hooks/useResponsive';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';

const StoresQRCodesDrawer = ({ onClose, store }) => {
  const { stores } = useAuth();
  const isDesktop = useResponsive('up', 'lg');
  const [selectedStore, setSelectedStore] = useState(store);

  const handleStoreSelection = (store) => {
    setSelectedStore(store);
  };

  return (
    <Drawer
      anchor="bottom"
      open
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      sx={{
        zIndex: 9999,
        '.MuiDrawer-paper': {
          borderRadius: '16px 16px 0 0',
        },
      }}
    >
      <Card
        sx={{
          p: (theme) => (isDesktop ? theme.spacing(4, 10) : theme.spacing(4, 2, 6, 2)),
        }}
      >
        <Stack alignItems="center" mb={4}>
          <Typography variant="h3">Inviter via QR Code</Typography>
          <Typography
            sx={{
              color: '#9B9B9B',
            }}
          >
            {selectedStore ? selectedStore.name : 'Choisissez un magasin'}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon={'eva:close-fill'} />
          </IconButton>
        </Stack>

        {selectedStore && <DetailedStoreQRCodeStepsDrawer store={selectedStore} />}
        {!selectedStore && (
          <Scrollbar sx={{ maxHeight: '70vh' }}>
            <Stack spacing={1}>
              {stores.map((store) => (
                <Card
                  key={store.id}
                  onClick={() => {
                    handleStoreSelection(store);
                  }}
                  sx={{
                    cursor: 'pointer',
                    p: 2,
                    borderRadius: '8px',
                    border: 'none',
                    backgroundColor: (theme) => theme.palette.background.paper,
                  }}
                >
                  <Stack direction="row" alignItems="center">
                    <Stack
                      sx={{
                        flex: 1,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          wordBreak: 'break-word',
                          overflowWrap: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {store.name.toUpperCase()}
                      </Typography>
                      <Typography
                        variant="body"
                        sx={{
                          color: '#8F8F8F',
                        }}
                      >
                        {store.code}
                      </Typography>
                    </Stack>
                    <QRCodeSVG value={store.invitationUrl} size={55} />
                  </Stack>
                </Card>
              ))}
            </Stack>
          </Scrollbar>
        )}
      </Card>
    </Drawer>
  );
};

export default StoresQRCodesDrawer;
