import { gql } from '@apollo/client';

const SAVE_DEVICE = gql`
  mutation saveDevice($input: SaveDeviceInput!) {
    saveDevice(input: $input) {
      id
      identifier
      name
      type
      authorizedAt
      inactive
      isMuted
    }
  }
`;

const LINK_TO_STORE = gql`
  mutation linkToStore($deviceId: ID!, $storeId: ID!) {
    linkToStore(deviceId: $deviceId, storeId: $storeId)
  }
`;

export { SAVE_DEVICE, LINK_TO_STORE };
