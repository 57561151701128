import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import CONFIG from '../../config/index';

const getTokenFromLocalStorage = () => {
  const token = window.localStorage.getItem('token');
  return token;
};

// =================================================================================================
// Http link
// =================================================================================================

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getTokenFromLocalStorage();

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
    uri: CONFIG.apiUrlHttp,
    ...operation.getContext(),
  });

  return forward(operation);
});

// inspired from here https://github.com/apollographql/apollo-feature-requests/issues/153#issuecomment-513148734
const errorLink = onError(({ networkError }) => {
  if (networkError && networkError.bodyText) {
    // Check if error response is JSON
    try {
      JSON.parse(networkError.bodyText);
    } catch (e) {
      // If not replace parsing error message with real one
      // eslint-disable-next-line no-param-reassign
      networkError.message = networkError.bodyText;
    }
  }
});

const uploadLink = createUploadLink();

// Order matter!
const httpLink = ApolloLink.from([authMiddleware, errorLink, uploadLink]);

// =================================================================================================
// Websocket link
// =================================================================================================

const getWebsocketUri = () => {
  const wsUrl = CONFIG.apiUrlWs;
  if (wsUrl.startsWith('wss://') || wsUrl.startsWith('ws://')) return wsUrl;
  const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
  return `${protocol}${window.location.host}${wsUrl}`;
};

const wsLink = new WebSocketLink({
  options: {
    connectionParams: () => ({
      token: getTokenFromLocalStorage(),
    }),
    reconnect: true,
  },
  uri: getWebsocketUri(),
});

// =================================================================================================
// Http and websocket client
// =================================================================================================

// according to the docs we choose the proper link depending on the operation (websocket/http)
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink,
});

export default apolloClient;
export { wsLink };
