import { Stack, Skeleton } from '@mui/material';

export default function SkeletonDeviceSubscription() {
  return (
    <Stack>
      <Stack spacing={1} direction="row" alignItems="flex-start">
        <Skeleton variant="text" width={92} height={22} />
        <Skeleton variant="text" width={56} height={22} />
      </Stack>
      <Skeleton variant="text" width={124} height={18} />
      <Stack alignItems="center" mt={1}>
        <Skeleton variant="text" width={92} height={32} />
      </Stack>
    </Stack>
  );
}
