import USER_ROLES from '../constants/userRoles';
import { PATH_DASHBOARD } from '../routes/paths';
import useAuth from './useAuth';

const useMainPath = () => {
  const { user } = useAuth();
  switch (user.role) {
    case USER_ROLES.ADMIN:
      return PATH_DASHBOARD.stores.list;
    default:
      return PATH_DASHBOARD.home;
  }
};

export default useMainPath;
