const deepCompareValues = (obj1, obj2) => {
  let differences = 0;

  function compare(current, initial) {
    Object.keys(current).forEach((key) => {
      if (typeof current[key] === 'object' && current[key] !== null && initial[key] !== null) {
        compare(current[key], initial[key]);
      } else if (current[key] !== initial[key]) {
        differences += 1;
      }
    });
  }

  compare(obj1, obj2);

  return differences;
};

export default deepCompareValues;
