import { useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import { Typography } from '@mui/material';
import anonymizeEmail from '../../../../utils/anonymizeEmail';
import SecurityCodeForm from './SecurityCodeForm';

const SecurityCodeFormTitle = () => (
  <>
    <Typography variant="h3" gutterBottom component="div">
      Entrez le code de sécurité
    </Typography>
  </>
);

const SecurityCodeFormSubtitle = () => {
  const { watch } = useFormContext();
  const formValues = watch();
  const anonymizedEmail = anonymizeEmail(formValues.email);

  return (
    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
      {`Vous avez reçu un code à 4 chiffres à l'adresse email ${anonymizedEmail}`}
    </Typography>
  );
};

const getSecurityCodeFormConfiguration = ({ stepOperation, additionalOperation }) => {
  const schema = {
    securityCode: Yup.string()
      .required('Le code de sécurité est requis')
      .matches(/^\d{1,4}$/, 'Le code de sécurité doit être composé de 4 chiffres')
      .test('securityCodeLength', 'Le code de sécurité doit être composé de 4 chiffres', (val) => {
        const isLengthValid = val?.length === 4;
        return isLengthValid;
      }),
  };

  const defaultValues = {
    securityCode: '',
  };

  return {
    schema,
    defaultValues,
    FormComponent: (props) => <SecurityCodeForm {...props} additionalOperation={additionalOperation} />,
    submitButtonLabel: 'Suivant',
    TitleComponent: (props) => <SecurityCodeFormTitle {...props} />,
    SubtitleComponent: (props) => <SecurityCodeFormSubtitle {...props} />,
    stepOperation,
  };
};

export default getSecurityCodeFormConfiguration;
