import * as Yup from 'yup';
import { Typography, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import EmailForm from './EmailForm';

const EmailFormTitleDesktop = () => (
  <>
    <Typography variant="h3" gutterBottom component="div">
      Bienvenue sur Alfred.ai
    </Typography>
  </>
);

const EmailFormTitleMobile = () => (
  <>
    <Typography variant="h3" gutterBottom component="div">
      L'intelligence artificielle
      <br />
      qui vous <Highlight>protège</Highlight> des vols
    </Typography>
  </>
);

const EmailFormSubtitleDesktop = ({ storeName, invitationCode }) => {
  const wording = invitationCode
    ? `Veuillez renseigner votre adresse email pour continuer à vous enregistrer à ${storeName}.`
    : `Veuillez renseigner votre adresse email pour vous connecter`;
  return (
    <>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {wording}
      </Typography>
    </>
  );
};

const EmailFormSubtitleMobile = ({ storeName, invitationCode }) => {
  const wording = invitationCode
    ? `Veuillez renseigner votre adresse email pour continuer à vous enregistrer à ${storeName}.`
    : `Veuillez renseigner votre adresse email pour vous connecter`;
  return (
    <>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {wording}
      </Typography>
    </>
  );
};

const Highlight = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const AdditionalComponentActionDesktop = () => (
  <Typography variant="caption" sx={{ color: 'text.tertiary', textAlign: 'center' }}>
    En continuant, vous confirmez avoir lu et vous acceptez les&nbsp;
    <Link href="https://alfred-eyes.ai/mentions-legales/" target="_blank" rel="noopener">
      conditions générales d’utilisation.
    </Link>
  </Typography>
);

const getEmailFormConfiguration = ({ stepOperation, isDesktop, storeName, invitationCode }) => {
  const schema = {
    email: Yup.string().email(`L'email doit être une adresse email valide`).required(`L'email est requis`),
  };

  const defaultValues = {
    email: '',
  };

  return {
    schema,
    defaultValues,
    FormComponent: (props) => <EmailForm {...props} isDesktop={isDesktop} />,
    TitleComponent: (props) => (isDesktop ? <EmailFormTitleDesktop {...props} /> : <EmailFormTitleMobile {...props} />),
    AdditionalComponentAction: (props) => (isDesktop ? <AdditionalComponentActionDesktop {...props} /> : null),
    SubtitleComponent: (props) =>
      isDesktop ? (
        <EmailFormSubtitleDesktop storeName={storeName} invitationCode={invitationCode} {...props} />
      ) : (
        <EmailFormSubtitleMobile storeName={storeName} invitationCode={invitationCode} {...props} />
      ),
    submitButtonLabel: 'Suivant',
    ...(stepOperation && { stepOperation }),
  };
};

export default getEmailFormConfiguration;
