import { Box, Collapse, IconButton, Stack, Tooltip } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router';
import useAuth from '../../../../hooks/useAuth';
import Iconify from '../../../../components/Iconify';

const DeviceActions = ({ isMuted, onSendTestAlert, onToggleMute }) => {
  const isMutedIcon = isMuted ? 'mdi:notifications-off' : 'mdi:notifications';
  const { selectedStore, onChangeStore } = useAuth();
  const { pathname } = useLocation();
  const isOnStoreView = pathname.includes('/stores/');

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Stack spacing={1} direction="row" justifyContent="center">
        {/* <Collapse orientation="horizontal" in={!isOnStoreView}>
          <Tooltip title="Voir le magasin">
            <IconButton onClick={() => onChangeStore(selectedStore)} size="small" color="inherit" variant="outlined">
              <Iconify icon="eva:corner-up-right-fill" />
            </IconButton>
          </Tooltip>
        </Collapse> */}
        <Tooltip title="Envoyer une alerte de test sur cet appareil">
          <IconButton onClick={onSendTestAlert} size="small" color="inherit" variant="outlined">
            <Iconify icon="eva:flash-fill" />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Mettre en sourdine les alertes de ce magasin">
          <IconButton onClick={onToggleMute} size="small" color="inherit" variant="outlined">
            <Iconify icon={isMutedIcon} />
          </IconButton>
        </Tooltip> */}
      </Stack>
    </Box>
  );
};

export default DeviceActions;
