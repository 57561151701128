import { useContext } from 'react';
import { PathHistoryContext } from '../contexts/PathHistoryContext';

const usePathHistory = () => {
  const context = useContext(PathHistoryContext);

  if (!context) throw new Error('PathHistory context must be use inside PathHistoryProvider');

  return context;
};

export default usePathHistory;
