import { useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { RHFTextField } from '../../../../components/hook-form';
import Iconify from '../../../../components/Iconify';

export default function PasswordsForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  return (
    <>
      <RHFTextField
        name="password"
        label="Mot de passe"
        type={showPassword ? 'text' : 'password'}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                <Iconify icon={showPassword ? 'eva:eye-off-fill' : 'eva:eye-fill'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <RHFTextField
        name="passwordConfirmation"
        label="Confirmer le mot de passe"
        type={showPasswordConfirmation ? 'text' : 'password'}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                edge="end"
              >
                <Iconify icon={showPasswordConfirmation ? 'eva:eye-off-fill' : 'eva:eye-fill'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}
