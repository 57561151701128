import { gql } from '@apollo/client';

const CURRENT_USER = gql`
  {
    me {
      id
      firstName
      lastName
      email
      displayName
      inactive
      role
      avatarUrl
    }
  }
`;

const MY_STORES = gql`
  query myStores {
    myStores {
      id
      name
      code
      inactive
      invitationUrl
      collaborators {
        id
        firstName
        lastName
        role
        inactive
        email
        avatarUrl
      }
    }
  }
`;

const LIST_USERS = gql`
  query listUsers($filters: FiltersUserInput, $pagination: Pagination!) {
    listUsers(filters: $filters, pagination: $pagination) {
      total
      hasMore
      users {
        id
        firstName
        lastName
        displayName
        email
        role
        inactive
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_USER = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      displayName
      email
      inactive
      stores {
        id
        code
        name
        inactive
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

const VERIFY_CODE = gql`
  query verifyCode($email: String!, $code: String!) {
    verifyCode(email: $email, code: $code)
  }
`;

export { CURRENT_USER, MY_STORES, LIST_USERS, GET_USER, VERIFY_CODE };
