import { Button } from '@mui/material';
import React from 'react';
import config from '../../../../config/index';

async function askPermission() {
  // eslint-disable-next-line no-undef
  const permission = await Notification.requestPermission();
  return permission === 'granted';
}

async function getVapidPublicKey() {
  // eslint-disable-next-line no-undef
  const { key } = await fetch(`${config.apiUrlHttp}/rest/push/vapid-public-key`, {
    headers: {
      Accept: 'application/json',
    },
  }).then((res) => res.json());

  return key;
}

const AuthorizeButton = ({ onPermissionChange, onSubscribe, loading }) => {
  const handleClick = async () => {
    const isGranted = await askPermission();
    onPermissionChange(isGranted);

    if (isGranted) {
      //
      const registration = await navigator.serviceWorker.getRegistration();
      let subscription = await registration.pushManager.getSubscription();
      if (!subscription) {
        subscription = await registration.pushManager.subscribe({
          applicationServerKey: await getVapidPublicKey(),
          userVisibleOnly: true,
        });
      }

      // Save the device and pass it the subscription
      if (onSubscribe) onSubscribe(subscription);
    }
  };
  return (
    <Button loading={loading} variant="contained" onClick={handleClick}>
      Autoriser
    </Button>
  );
};

export default AuthorizeButton;
