import { useContext } from 'react';
import { QueryParamsContext } from '../contexts/QueryParamsContext';

const useQueryParams = () => {
  const context = useContext(QueryParamsContext);

  if (!context) throw new Error('QueryParams context must be use inside QueryParamsProvider');

  return context;
};

export default useQueryParams;
