import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import Iconify from '../Iconify';

const ConfirmDialog = ({ message, onConfirm, onDiscard, open, title }) => (
  <Dialog
    fullWidth
    maxWidth="xs"
    open={open}
    onClose={onDiscard}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle sx={{ p: 0 }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography>{title}</Typography>
        <IconButton onClick={onDiscard}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Toolbar>
    </DialogTitle>
    <Divider />
    <DialogContent>{message}</DialogContent>
    <Divider />
    <DialogActions sx={{ p: 2 }}>
      <Stack spacing={2} direction="row" justifyContent="flex-end" width="100%">
        <Button
          onClick={onDiscard}
          sx={{
            color: 'primary.main',
            width: '100%',
          }}
        >
          Annuler
        </Button>
        <Button onClick={onConfirm} variant="contained" sx={{ width: '100%' }}>
          Confirmer
        </Button>
      </Stack>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
